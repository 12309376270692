/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./secure-verify-page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./secure-verify-page";
import * as i5 from "@ionic/storage";
var styles_SecureVerifyPage = [i0.styles];
var RenderType_SecureVerifyPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SecureVerifyPage, data: {} });
export { RenderType_SecureVerifyPage as RenderType_SecureVerifyPage };
export function View_SecureVerifyPage_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), i1.ɵqud(402653184, 2, { secureFrame: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "ion-content", [["style", "background: white"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(3, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 8, "div", [["class", "iframe-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "iframe-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "ion-button", [["class", "dismiss-btn"], ["color", "dark"], ["fill", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(7, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], fill: [1, "fill"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "ion-icon", [["name", "md-close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(10, 0, [[2, 0], ["secure", 1]], null, 0, "iframe", [["style", "height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "loader-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "dark"; var currVal_1 = "clear"; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = "md-close"; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_SecureVerifyPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-secure-verify", [], null, null, null, View_SecureVerifyPage_0, RenderType_SecureVerifyPage)), i1.ɵdid(1, 245760, null, 0, i4.SecureVerifyPage, [i1.Injector, i5.Storage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SecureVerifyPageNgFactory = i1.ɵccf("page-secure-verify", i4.SecureVerifyPage, View_SecureVerifyPage_Host_0, {}, {}, []);
export { SecureVerifyPageNgFactory as SecureVerifyPageNgFactory };
