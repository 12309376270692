import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SdkBaseService {

    testing = false;

    public sdkConfig: any = null;

    public get isCrystalJade(): boolean {
        return this.getSource === 'crystalJade';
    }
    public get getSource(): string {
    
        let source = this.sdkConfig && this.sdkConfig['member'] && this.sdkConfig['member']['source'] || ""
        console.log("JJ: SdkBaseService -> this.sdkConfig", this.sdkConfig);
        return source;
    }
    public get getMemberCardNo(): string {
        let cardNo = this.sdkConfig && this.sdkConfig['member'] && this.sdkConfig['member']['cardNo'] || "";
        console.log("JJ: SdkBaseService -> this.sdkConfig", this.sdkConfig);
        return cardNo;
    }


    protected getServiceName() {
        return null;
    }

    public isAvailable() {
        const ios = window['webkit'];

        const serviceName = this.getServiceName();

        if (typeof ios !== 'undefined') {
            if (ios.messageHandlers[serviceName]) {
                return true;
            }
        } else {

            if (typeof window[serviceName] !== 'undefined') { return true; }
        }
        return false;
    }

    protected callNative(method: string, params: any, callback = null) {

        const serviceName: string = this.getServiceName();

        let cbName: string = null;

        if (callback) {
            cbName = serviceName + method + 'Cb';

            window[cbName] = function (str) {
                callback(JSON.parse(str));
                window[cbName] = null;
            };
        }

        if (cbName) {
            params['_callback'] = cbName;
        }
        params['_method'] = method;

        const webkit = window['webkit'];
        if (webkit) {

            webkit.messageHandlers[serviceName].postMessage(params);
        } else {

            console.log('calling android');
            console.log(window[serviceName]);

            const input = JSON.stringify(params);

            window[serviceName].postMessage(input);

            console.log('done');
        }


    }

}
