import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {debounceTime, throttleTime} from 'rxjs/operators';
import {Subscription} from 'rxjs/Subscription';

@Directive({
    selector: '[appThrottleClick]'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
    @Input() throttleTime = 300;
    @Output() throttleClick = new EventEmitter();
    private clicks = new Subject();
    private subscription: Subscription;

    constructor() {
    }

    ngOnInit() {
        this.subscription = this.clicks.pipe(
            throttleTime(this.throttleTime)
        ).subscribe(e => this.throttleClick.emit(e));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }

}
