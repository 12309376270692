import { NgModule } from '@angular/core';

import { SwUpdatesService } from './sw-updates.service';


@NgModule({
  providers: [
    SwUpdatesService
  ]
})
export class SwUpdatesModule {}
