import * as Bowser from 'bowser';
import * as i0 from "@angular/core";
var BrowserService = /** @class */ (function () {
    function BrowserService() {
        this.browser = Bowser;
        console.log(Bowser);
        if (window) {
            this.userAgent = window.navigator.userAgent;
        }
        this.name = this.getBrowserName();
        this.version = Bowser.version;
        this.mobile = Bowser.mobile;
        this.tablet = Bowser.tablet;
        this.android = Bowser.android;
        this.ios = Bowser.ios;
    }
    BrowserService.prototype.isSupported = function () {
        if (!this.isNgSupported()) {
            return false;
        }
        // if not set, everything is supported
        if (!this.supported) {
            return true;
        }
    };
    BrowserService.prototype.isNgSupported = function () {
        if (!this.browser.check({ msie: '9' })) {
            return false;
        }
        if (!this.browser.check({ msedge: '13' })) {
            return false;
        }
        return true;
    };
    BrowserService.prototype.isFastNgSupported = function () {
        if (!this.browser.check({ msie: '100' })) {
            return false;
        }
        if (!this.browser.check({ msedge: '15' })) {
            return false;
        }
        return true;
    };
    BrowserService.prototype.getBrowserName = function () {
        var result = Bowser.name;
        if (this.isWeChat()) {
            result = 'WeChat';
        }
        else if (this.isFacebook()) {
            result = 'Facebook';
        }
        return result;
    };
    BrowserService.prototype.isWeChat = function () {
        return this.userAgent.toLowerCase().indexOf('micromessenger') !== -1 && window['__wxjs_environment'] !== 'miniprogram';
    };
    BrowserService.prototype.isWeChatMiniProgramWebview = function () {
        return window['__wxjs_environment'] === 'miniprogram';
    };
    BrowserService.prototype.isWeChatHK = function () {
        return this.userAgent.toLowerCase().indexOf('micromessenger') !== -1 && window['__wxjs_environment'] !== 'miniprogram';
    };
    // cn alipay browser open
    BrowserService.prototype.isAlipay = function () {
        return this.userAgent.toLowerCase().indexOf('alipayclient') !== -1 && !this.isAlipayHK();
    };
    // HK alipay browser open
    BrowserService.prototype.isAlipayHK = function () {
        return this.userAgent.toLowerCase().indexOf('alipayclienthk') !== -1;
    };
    BrowserService.prototype.isFacebook = function () {
        var ua = this.userAgent;
        return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
        // return "Facebook" === Bowser.version;
    };
    BrowserService.prototype.isBKApp = function () {
        var ua = this.userAgent;
        return (ua.indexOf('bkapp') !== -1);
    };
    BrowserService.prototype.isChrome = function () {
        return this.browser.name === 'Chrome';
    };
    BrowserService.prototype.isSupportGooglePay = function () {
        return this.isChrome() && Number(this.browser.version) >= 61;
    };
    BrowserService.prototype.isSupportNativeGooglePay = function () {
        return (window['GooglePayService']);
    };
    BrowserService.prototype.isInApp = function () {
        // return this.isWeChat() || this.isFacebook() || this.isBKApp();
        return this.isBKApp();
    };
    BrowserService.ngInjectableDef = i0.defineInjectable({ factory: function BrowserService_Factory() { return new BrowserService(); }, token: BrowserService, providedIn: "root" });
    return BrowserService;
}());
export { BrowserService };
