/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./survey.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./survey";
import * as i6 from "../../core/services/member.service";
import * as i7 from "../../core/services/order.service";
var styles_SurveyDialog = [i0.styles];
var RenderType_SurveyDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_SurveyDialog, data: {} });
export { RenderType_SurveyDialog as RenderType_SurveyDialog };
export function View_SurveyDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-content", [["class", "no-bg"], ["overflow-scroll", "false"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "div", [["class", "survey-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "survey-wrapper"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "height.px": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "iframe", [["id", "target-dom-node"], ["style", "width:100%;height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ion-button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "ion-icon", [["color", "dark"], ["name", "ios-close"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(10, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, (_co.deHeight - 128)); _ck(_v, 4, 0, currVal_0); var currVal_1 = "dark"; var currVal_2 = "ios-close"; _ck(_v, 10, 0, currVal_1, currVal_2); }, null); }
export function View_SurveyDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-survey", [], null, null, null, View_SurveyDialog_0, RenderType_SurveyDialog)), i1.ɵdid(1, 114688, null, 0, i5.SurveyDialog, [i6.MemberService, i7.OrderService, i3.ModalController, i3.NavParams], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SurveyDialogNgFactory = i1.ɵccf("page-survey", i5.SurveyDialog, View_SurveyDialog_Host_0, {}, {}, []);
export { SurveyDialogNgFactory as SurveyDialogNgFactory };
