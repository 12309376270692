var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import * as firebase from 'firebase';
import 'firebase/database';
import 'rxjs/add/operator/map';
import { OrderManager } from 'aigens-ng-core';
import { MemberService } from './member.service';
import { LoadingController, ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./member.service";
import * as i3 from "./config.service";
import * as i4 from "aigens-ng-core/dist/manager/order-manager";
/**
 *  Realtime Sharing Cart Service
 * */
var CartService = /** @class */ (function (_super) {
    __extends(CartService, _super);
    function CartService(loadingController, toastController, memberService, configs, orderManger) {
        var _this = _super.call(this) || this;
        _this.loadingController = loadingController;
        _this.toastController = toastController;
        _this.memberService = memberService;
        _this.configs = configs;
        _this.orderManger = orderManger;
        _this.refs = [];
        _this.refsByKey = {};
        _this.enable = false;
        _this.lastLock = null;
        _this.locking = false;
        _this.loader = null;
        if (_this.configs.isChina()) {
            return _this;
        }
        var config = _this.configs.config.getFirebaseConfig();
        if (!_this.configs.firebase) {
            _this.configs.firebase = firebase.initializeApp(config);
            firebase.auth();
        }
        _this.database = firebase.database();
        return _this;
    }
    CartService.prototype.initSharingCart = function (storeId, spot) {
        var _this = this;
        if (Number.isNaN(spot) || !spot || !this.enable) {
            console.log('invalid spot, not init sharing cart');
            this.enable = false;
            return;
        }
        console.log('init sharing cart');
        this.enable = true;
        firebase.database().ref("/cart/" + storeId + "/" + spot + "/members").once('value', function (data) {
            _this.sharingCart = data.val();
            console.log('sharing cart init', _this.sharingCart);
            _this.lastLock = null;
            _this.locking = false;
            _this.storeId = storeId;
            _this.spot = spot;
            _this.initAt = Date.now();
            if (_this.configs.getLocal('realcart.key') && _this.sharingCart && _this.sharingCart[_this.configs.getLocal('realcart.key')]) {
                _this.key = _this.configs.getLocal('realcart.key');
                if (_this.sharingCart[_this.key]['orderitems'] && _this.orderManger.order) {
                    _this.orderManger.order.orderitems = _this.sharingCart[_this.key]['orderitems'];
                    _this.orderManger.calculate();
                }
                firebase.database().ref("/cart/" + storeId + "/" + spot + "/members/" + _this.key + "/join").push(Date.now());
            }
            else {
                // TODO: implement initialization data
                var toSave = {
                    avatar: _this.getAvatarUrl(_this.memberService.name),
                    uuid: _this.memberService.uuid,
                    name: _this.memberService.name,
                    orderitems: [],
                    join: Date.now()
                };
                _this.key = firebase.database().ref("/cart/" + storeId + "/" + spot + "/members").push(toSave).key;
                _this.configs.setLocal('realcart.key', _this.key);
            }
            // On your order item changes, this may trigger by you or someone submit your items
            firebase.database().ref("/cart/" + _this.storeId + "/" + _this.spot + "/members/" + _this.key + "/orderitems").on('value', function (data) {
                _this.myCart = data.val();
                if (_this.myCart === undefined) {
                    _this.myCart = [];
                    if (_this.orderManger.order) {
                        _this.orderManger.order.orderitems = _this.myCart;
                        _this.orderManger.calculate();
                    }
                }
                console.log('your cart change', data.val());
            });
            firebase.database().ref("/cart/" + storeId + "/" + spot + "/members").on('value', function (data) {
                _this.sharingCart = data.val();
                console.log('sharing cart change', _this.sharingCart);
            });
            firebase.database().ref("/cart/" + storeId + "/" + spot + "/members").on('child_added', function (data) {
                console.log('on child_added', data.val());
                var t = data.val();
                if (t['uuid'] !== _this.memberService.uuid && t['join'] > _this.initAt) {
                    _this.toastController.create({
                        message: 'someone joined',
                        duration: 2000,
                        position: 'top'
                    }).then(function (modal) {
                        modal.present();
                    });
                }
            });
            firebase.database().ref("/cart/" + storeId + "/" + spot + "/members").on('child_changed', function (data) {
                console.log('on child_changed', data.val());
                var t = data.val();
                if (t['uuid'] !== _this.memberService.uuid) {
                    _this.toastController.create({
                        message: 'someone changed the cart',
                        duration: 2000,
                        position: 'top'
                    }).then(function (modal) {
                        modal.present();
                    });
                }
            });
            firebase.database().ref("/cart/" + storeId + "/" + spot + "/lock").on('value', function (data) {
                console.log('on lock', data.val());
                var t = data.val();
                if (_this.lastLock && !t) {
                    if (_this.lastLock['uuid'] !== _this.memberService.uuid) {
                        _this.toastController.create({
                            message: 'current order is submitted',
                            duration: 2000,
                            position: 'top'
                        }).then(function (modal) {
                            modal.present();
                        });
                    }
                    if (_this.loader) {
                        _this.loader.dismiss();
                    }
                    _this.locking = false;
                }
                else {
                    _this.onCartLocked(data.val());
                }
            });
            _this.orderManger.onOrderitemsChange.subscribe(function (data) {
                if (!_this.locking) {
                    _this.updateSharingCart();
                }
            });
        });
    };
    CartService.prototype.updateSharingCart = function () {
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return;
        }
        var temp = this.orderManger.order.orderitems;
        console.log('temp input', temp);
        temp.forEach(function (orderitem) {
            Object.keys(orderitem).forEach(function (key) { return orderitem[key] === undefined && delete orderitem[key]; });
            Object.keys(orderitem.category).forEach(function (key) { return orderitem.category[key] === undefined && delete orderitem.category[key]; });
            if (orderitem.groups) {
                orderitem.groups.forEach(function (group) {
                    Object.keys(group).forEach(function (key) { return group[key] === undefined && delete group[key]; });
                    if (group.items) {
                        group.items.forEach(function (item) {
                            Object.keys(item).forEach(function (key) { return item[key] === undefined && delete item[key]; });
                            if (item.mgroups) {
                                item.mgroups.forEach(function (mgroup) {
                                    Object.keys(mgroup).forEach(function (key) { return mgroup[key] === undefined && delete mgroup[key]; });
                                    if (mgroup.items) {
                                        mgroup.items.forEach(function (mitem) {
                                            Object.keys(mitem).forEach(function (key) { return mitem[key] === undefined && delete mitem[key]; });
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        console.log('temp output', temp);
        this.myCart = temp;
        firebase.database().ref("/cart/" + this.storeId + "/" + this.spot + "/members/" + this.key + "/orderitems").set(temp);
    };
    CartService.prototype.getOthersCart = function () {
        var _this = this;
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return [];
        }
        var temp = [];
        Object.keys(this.sharingCart).forEach(function (memberKey) {
            if (memberKey !== _this.key) {
                var t = _this.sharingCart[memberKey];
                if (t['orderitems'] != null && t['orderitems'].lenthe !== 0) {
                    temp.push(_this.sharingCart[memberKey]);
                }
            }
        });
        // console.log('others cart', temp);
        return temp;
    };
    CartService.prototype.lockSharingCart = function () {
        var _this = this;
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return Promise.resolve({ committed: true });
        }
        var t = {
            uuid: this.memberService.uuid,
            name: this.memberService.name,
            lockTime: Date.now()
        };
        return firebase.database().ref("/cart/" + this.storeId + "/" + this.spot + "/lock").transaction(function (data) {
            console.log('on lock callbacks', data);
            if (data) {
                console.log('someone is saving the demo');
                return;
            }
            else {
                console.log('the lock is empty, can update');
                t.lockTime = Date.now();
                return t;
            }
        }, function (error, isSuccess, data) {
            console.log('error', error);
            console.log('isSuccess', isSuccess);
            console.log('data', data);
            if (isSuccess) {
                console.log('this.sharing cart', _this.sharingCart);
                Object.keys(_this.sharingCart).forEach(function (memberKey) {
                    var t = _this.sharingCart[memberKey];
                    if (t.uuid === _this.memberService.uuid) {
                        return;
                    }
                    var tt = t['orderitems'];
                    if (!tt) {
                        return;
                    }
                    console.log('lock loop', t);
                    t['orderitems'].forEach(function (orderitem) {
                        console.log('lock add', orderitem);
                        _this.orderManger.addOrderItem(orderitem);
                    });
                });
            }
            else {
                _this.onCartLocked(data.val());
            }
        });
    };
    CartService.prototype.unlockSharingCart = function () {
        var _this = this;
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return;
        }
        firebase.database().ref("/cart/" + this.storeId + "/" + this.spot + "/lock").remove();
        Object.keys(this.sharingCart).forEach(function (memberKey) {
            firebase.database().ref("/cart/" + _this.storeId + "/" + _this.spot + "/members/" + memberKey + "/orderitems").remove();
        });
        this.sharingCart = {};
        this.locking = false;
    };
    CartService.prototype.getAvatarUrl = function (index) {
        return 'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + index;
    };
    CartService.prototype.onCartLocked = function (lock) {
        var _this = this;
        if (!this.enable) {
            console.log('sharing cart is not enabled, return');
            return;
        }
        if (!this.locking && lock) {
            this.locking = true;
            if (lock['uuid'] !== this.memberService.uuid) {
                this.loadingController.create({
                    spinner: null,
                    keyboardClose: false,
                    message: 'someone is submitting current order',
                    translucent: true,
                    cssClass: 'custom-class custom-loading'
                }).then(function (loader) {
                    _this.loader = loader;
                    loader.present();
                });
            }
            // this.toastController.create({
            //     message: lock['name'] + ' is submitting current order',
            //     duration: 2000,
            //     position: 'top'
            // }).then(modal => {
            //     modal.present();
            // });
            this.lastLock = lock;
        }
    };
    CartService.ngInjectableDef = i0.defineInjectable({ factory: function CartService_Factory() { return new CartService(i0.inject(i1.LoadingController), i0.inject(i1.ToastController), i0.inject(i2.MemberService), i0.inject(i3.ConfigService), i0.inject(i4.OrderManager)); }, token: CartService, providedIn: "root" });
    return CartService;
}(BaseService));
export { CartService };
