var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Item, ModifierBuilder, OrderItemBuilder, OrderManager } from 'aigens-ng-core';
import { ChangeDetectorRef, Injector, OnInit } from '@angular/core';
import { SideBarModifierDialog } from '../../../dialogs/sideBarModifier/sideBarModifier';
import { EnlargePage } from './enlargePage/enlargePage';
import { ModifierItemSelectPageModal } from '../modifier-item-select-modal/modifier-item-select-modal';
import { NavParams } from '@ionic/angular';
import { popDrownAnimation, popUpAnimation } from 'src/app/shared/animations/leaveAnimation';
import { BaseComboPage } from '../../../core/base/base-combo-page';
import { RouteTable } from 'src/app/core/route-table';
var ComboPage = /** @class */ (function (_super) {
    __extends(ComboPage, _super);
    function ComboPage(injector, cd, modalNavParams) {
        var _this = _super.call(this, injector) || this;
        _this.cd = cd;
        _this.modalNavParams = modalNavParams;
        _this.defaultModifierPage = SideBarModifierDialog;
        _this.tagsImage = {};
        // subgroup expand status
        _this.autoPickupGroups = []; // html暂时未show
        _this.nonAutoPickupGroups = [];
        _this.requiredModifierMap = {};
        _this.modifiableMap = {};
        _this.defaultModifierPage = SideBarModifierDialog;
        console.log('toggle set menu page construction', typeof _this.defaultModifierPage);
        return _this;
    }
    // to fix the bug of noneed (this.groups will change after the constructor() implement)
    ComboPage.prototype.initPage = function () {
        var _this = this;
        console.log('ComboPage set menu page construction');
        this.store = this.modalNavParams.get('store');
        this.edit = this.modalNavParams.get('edit');
        this.items = this.modalNavParams.get('items');
        this.originalGroups = this.modalNavParams.get('groups');
        if (this.store) {
            this.preselectItem = this.settings.preselectItem;
            this.isAutoPickup = this.settings.autoPickup;
        }
        if (this.multOrderManager.enableMutlOrder) {
            this.settingMultiOrderManager();
        }
        else if (this.orderManager.isEmpty()) {
            this.orderManager.createOrder(this.store.id, null, null, null, this.store);
        }
        this.orderManager.settingOrderType(this.orderManager.mode);
        var groups;
        var mainItem;
        this.builder = new OrderItemBuilder(this.store);
        if (!this.edit) {
            this.category = this.modalNavParams.get('category');
            // category.groups
            groups = this.modalNavParams.get('groups');
            mainItem = this.modalNavParams.get('item');
        }
        else {
            var origins = this.builder.findOrigins(this.edit);
            this.category = origins.category;
            groups = origins.groups;
            mainItem = origins.item;
        }
        this.builder.category = this.category;
        if (mainItem.groups && mainItem.groups.length > 0) {
            this.mainGroup = groups[0]; // buger页的所有数据
            groups = mainItem.groups; // 当前特定bugger的所有items Groups
            this.mainItem = mainItem;
            this.mainItem.quantity = 1;
        }
        else {
            // var choices = groups[0].choices;
            var min = groups[0].min;
            var max = groups[0].max;
            var choices = groups[0].choices;
            if ((max === 1 && min === 1) || choices === 1) {
                this.mainGroup = groups[0];
                groups = groups.slice(1);
                this.mainItem = mainItem;
                this.mainItem.quantity = 1;
            }
        }
        if (this.store) {
            this.handleOrderManager();
        }
        // DEEP CLONE!
        groups = groups && groups.filter(function (group) {
            return _this.menuManager.isAvailableGroup(_this.store, _this.category, group, _this.orderManager.order.takeout, Date.now(), false);
        });
        this.groups = groups;
        if (!this.edit) {
            this.builder.setItem(this.mainGroup, this.mainItem, 0, this.mainItem.quantity, false, false);
        }
        else {
            // this.builder.transferSubitems(this.edit, this.groups);
            // include mainitem
            this.builder.transferAllItems(this.edit, this.groups);
            // build all no need subitem group
            for (var j = 0; j < this.groups.length; j++) {
                var matched = false;
                for (var i = 0; i < this.edit.groups.length; i++) {
                    // pass main item
                    if (i !== 0) {
                        if (this.groups[j].id === this.edit.groups[i].id) {
                            // console.log("matched and put", this.groups[j].name);
                            matched = true;
                            break;
                        }
                    }
                }
                if (!matched) {
                    // console.log('i am putting null clickedNoNeed: ', this.groups[j].name);
                    this.noNeedConfirm[this.groups[j].id] = true;
                    this.builder.clearItems(j + 1);
                    this.builder.build();
                }
            }
        }
        var orderItem = this.builder.build();
        this.modifierBuilder = new ModifierBuilder(this.store, orderItem);
        if (this.edit) {
            this.modifierBuilder.transfer(this.edit);
            this.modifierBuilder.build();
        }
        this.flatten();
        this.total = this.mainItem.price;
        var badges = this.store.menu.badges;
        if (badges) {
            badges.forEach(function (badge) {
                var tagImg = badge.getImage('default', 400, true);
                if (tagImg) {
                    _this.tagsImage[badge.tag] = tagImg;
                }
            });
        }
        this.updateSummaryDisplay();
    };
    ComboPage.prototype.updateSummaryDisplay = function () {
        console.log('builder!', this.builder.groups);
        var groups = this.builder.groups;
    };
    ComboPage.prototype.settingMultiOrderManager = function () {
        var storeId = this.store.id;
        if (storeId)
            return;
        if (this.multOrderManager.getOrderManager(storeId)) {
            this.orderManager = this.multOrderManager.getOrderManager(storeId);
        }
        else {
            var orderManager = new OrderManager();
            orderManager.mode = this.orderManager.mode;
            orderManager.createOrder(storeId, null, null, null, this.store);
            this.multOrderManager.setOrderManagerMap(orderManager, storeId);
            this.orderManager = this.multOrderManager.getOrderManager(storeId);
        }
    };
    ComboPage.prototype.dismissClicked = function () {
        this.modalController.dismiss();
    };
    ComboPage.prototype.modifierClicked = function () {
        return __awaiter(this, void 0, void 0, function () {
            var ois, params, popup;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.modifierShown)
                            return [2 /*return*/];
                        ois = this.builder.build();
                        console.log('modifierClicked orderitem:', ois);
                        params = { store: this.store, orderitem: ois, edit: true };
                        return [4 /*yield*/, this.modalController.create({
                                component: ModifierItemSelectPageModal,
                                componentProps: params,
                                cssClass: 'modal-largescreen modify-page ',
                                showBackdrop: false,
                                animated: true,
                                enterAnimation: popUpAnimation,
                                leaveAnimation: popDrownAnimation
                            })];
                    case 1:
                        popup = _a.sent();
                        popup.onDidDismiss().then(function (res) {
                            _this.modifierShown = false;
                            if (res && res.data) {
                                _this.checkAutoPickupModifierCompleted();
                                _this.builder.build(); // update the builder.items
                                _this.cd.detectChanges();
                            }
                        });
                        popup.present().then(function (data) {
                            _this.modifierShown = true;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ComboPage.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.initPage();
        console.log('on init', this.groups);
        this.handleModifier();
        this.checkAutoPickupHasModifier();
        this.checkAutoPickupModifierCompleted();
        this.cd.detectChanges();
        this.totalQty = 1;
    };
    // TODO: merge with the function above
    ComboPage.prototype.checkAutoPickupModifierCompleted = function () {
        console.log('checking isAutoPickupmodifiercompleted');
        var completed = true;
        console.log('this.builder.groups', this.builder.groups);
        console.log('this.mainItem', this.mainItem);
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {
                for (var _i = 0, _a = this.mainItem.mgroups; _i < _a.length; _i++) {
                    var mgroup = _a[_i];
                    if (mgroup.min > 0) {
                        var mgroups = this.builder.groups[0].items[0].mgroups;
                        if (mgroups) {
                            for (var i = 0; i < mgroups.length; i++) {
                                var quantities = 0;
                                for (var _b = 0, _c = mgroups[i].items; _b < _c.length; _b++) {
                                    var tmpItem = _c[_b];
                                    quantities += 1 * tmpItem.quantity;
                                }
                                if (mgroups[i].min > 0) {
                                    completed = quantities >= mgroups[i].min;
                                    if (!completed) {
                                        return;
                                    }
                                }
                            }
                        }
                        else {
                            completed = false;
                        }
                    }
                }
            }
        }
        for (var i = 0; i < this.mainItem.groups.length; i++) {
            console.log('i', i);
            // check autopickup group with mainItem to see if it needs modifier
            if (this.isAutoPickGroup(this.mainItem.groups[i])) {
                console.log('mainitem.group', this.mainItem.groups[i]);
                for (var _d = 0, _e = this.mainItem.groups[i].items; _d < _e.length; _d++) {
                    var item = _e[_d];
                    if (item.mgroups.length > 0) {
                        console.log('this.builder.groups', this.builder.groups);
                        console.log('this.builder.groups[i]', this.builder.groups[i + 1]);
                        var isItemMgroupMinGreaterThanZero = false;
                        if (!this.builder.groups[i + 1] || !this.builder.groups[i + 1].items || !this.builder.groups[i + 1].items[0] || !this.builder.groups[i + 1].items[0].mgroups) {
                            for (var _f = 0, _g = item.mgroups; _f < _g.length; _f++) {
                                var itemMgroup = _g[_f];
                                if (itemMgroup.min > 0) {
                                    isItemMgroupMinGreaterThanZero = true;
                                    break;
                                }
                            }
                            if (isItemMgroupMinGreaterThanZero) {
                                completed = false;
                                return;
                            }
                            else {
                                if (completed) {
                                    this.isAutoPickupModifierCompleted = true;
                                    return;
                                }
                            }
                        }
                        for (var j = 0; j < this.builder.groups[i + 1].items[0].mgroups.length; j++) {
                            var quantities = 0;
                            for (var _h = 0, _j = this.builder.groups[i + 1].items[0].mgroups[j].items; _h < _j.length; _h++) {
                                var tmpItem = _j[_h];
                                quantities += 1 * tmpItem.quantity;
                            }
                            if (item.mgroups[0].min > 0) {
                                completed = quantities >= item.mgroups[0].min;
                                if (!completed) {
                                    return;
                                }
                            }
                        }
                    }
                    else {
                        completed = true;
                    }
                }
            }
        }
        if (completed) {
            this.isAutoPickupModifierCompleted = true;
        }
    };
    ComboPage.prototype.showImage = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var defaultImage, imgUrl, page, modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        defaultImage = this.getDefaultImage(true, this.store.brand, 500);
                        imgUrl = item.getImage('default', 500, true) || defaultImage;
                        console.log('enlarge img', this.modalController);
                        page = EnlargePage;
                        return [4 /*yield*/, this.modalController.create({
                                component: page,
                                componentProps: { imgUrl: imgUrl },
                                animated: true,
                                showBackdrop: true,
                                backdropDismiss: true,
                                cssClass: 'modal-largescreen enlarge-modal'
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (confirm) {
                            console.log('close enlarge modal');
                        });
                        modal.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    // noNeed
    ComboPage.prototype.clickedNoNeed = function (group, index) {
        // if (this.noNeedConfirm[group.id]) {
        //     return;
        // }
        var groupIndex = this.groups.indexOf(group) + 1;
        console.log('group clickedNoNeed: ', groupIndex, group);
        console.log('this.builder', this.builder.groups[groupIndex]);
        var quantities = null;
        if (this.builder.groups[groupIndex] && this.builder.groups[groupIndex].items && this.builder.groups[groupIndex].items.length) {
            quantities = 0;
            // quantities = quantities + this.builder.getQuantity
            for (var _i = 0, _a = this.builder.groups[groupIndex].items; _i < _a.length; _i++) {
                var item = _a[_i];
                quantities = quantities + this.builder.getQuantityInGroup(groupIndex, item.id);
            }
            console.log('check quantities', quantities);
        }
        this.noNeedConfirm[group.id] = true;
        this.builder.clearItems(groupIndex);
        this.builder.build();
        // for checking next group
        // TODO: go to next incomplete group
        var itemGroup = this.groups[index + 1];
        console.log('checking next itemGrp', this.groups, index + 1);
        this.updateSelectedModifier();
        this.cd.detectChanges();
        // this.next();
    };
    ComboPage.prototype.totalQtyAddClicked = function () {
        this.totalQty += 1;
    };
    ComboPage.prototype.totalQtyMinusClicked = function () {
        if (this.totalQty > 1) {
            this.totalQty -= 1;
        }
    };
    ComboPage.prototype.addToCartClicked = function () {
        var _this = this;
        this.googleAnalyticEventTrack('add to cart');
        console.log('addToCartClicked', this.groups);
        if (!this.isCompleted() || !this.isAutoPickupModifierCompleted) {
            // let checked = false;
            this.missing = true;
            // TODO: scroll to the first incomplete group
            this.showNotCompletedAlert();
            return;
        }
        if (this.totalQty < 1) {
            this.showQtyAlert();
            return;
        }
        if (this.groups && this.groups.length > 0) {
            this.missing = false;
            var oi_1 = this.builder.build();
            oi_1.combo = true;
            var orderManager = void 0;
            if (this.multOrderManager.enableMutlOrder) {
                orderManager = this.multOrderManager.getOrderManager(this.store.id);
            }
            else {
                orderManager = this.orderManager;
            }
            // const handler = this.modalNavParams.get('handler');
            var handler_1 = this.modalNavParams.get('handler');
            if (!handler_1) {
                if (this.edit) {
                    // this.orderService.replaceOrderItem(this.edit, this.category, igs);
                    if (this.totalQty === 1) {
                        orderManager.replaceOrderItem(this.edit, oi_1);
                    }
                    else {
                        orderManager.replaceOrderItem(this.edit, oi_1);
                        for (var i = 0; i < this.totalQty - 1; i++) {
                            var oi_ = this.builder.build();
                            oi_.combo = true;
                            orderManager.addOrderItem(oi_1);
                        }
                    }
                }
                else {
                    // this.orderService.addSet(this.category, igs);
                    if (this.totalQty > 0) {
                        for (var i = 0; i < this.totalQty; i++) {
                            var oi_ = this.builder.build();
                            oi_.combo = true;
                            orderManager.addOrderItem(oi_);
                        }
                    }
                }
            }
            // this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
            // this.dismissClicked();
            // open the single page quickCheckout flow
            if (orderManager.store.menu && orderManager.store.menu.quickCheckout || handler_1) {
                this.modalController.dismiss().then(function () {
                    // this.pushByUrl(this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartPage'], {}, {replaceUrl: true}).then(
                    _this.pushByUrl(_this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartScrollPage'], {}).then(function () {
                        // find index of home page, and remove from home page
                        var page;
                        var catLayout = _this.settings.catLayout;
                        if (handler_1) {
                            handler_1(oi_1);
                        }
                    });
                });
            }
            else {
                // if (!this.edit) {
                //     this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
                //     // this.showToast(this.t.instant('global.item-added'));
                // }
                this.modalController.dismiss();
            }
        }
        else {
            if (this.isRequiredModifier(this.mainItem) || (this.isModifiable(this.mainItem) && !this.settings.quickSingleItem)) {
                // the foodItem special request select
                // tslint:disable-next-line:no-var-keyword
                var groups = this.category.groups, groups = groups && groups.filter(function (group) {
                    return _this.menuManager.isAvailableGroup(_this.store, _this.category, group, _this.orderManager.order.takeout, Date.now(), false);
                });
                //   let oi = this.orderManager.createOrderItem(cate, cate.groups[0], item);
                var oi = this.addItem(this.mainItem);
                this.add(this.mainItem, groups[0]);
                // this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
                // this.pushByName('ModifierItemSelectPage', { store: this.store, orderitem: oi, callback: cb }, {relativeTo: this.route, skipLocationChange: false});
                this.backButtonClick();
            }
            else {
                if (this.totalQty > 0) {
                    for (var i = 0; i < this.totalQty; i++) {
                        this.addItem(this.mainItem);
                    }
                }
                // this.showToast(this.t.instant('global.item-added'), 1000, 'bottom', 'add-item-toast');
                this.backButtonClick();
            }
        }
    };
    ComboPage.prototype.addItem = function (item) {
        var category = this.category;
        // this.ord(erService.addSingleItem(this.category, this.groups[0], item);
        console.log('om order', this.orderManager.getOrder());
        var oi = this.orderManager.addSingleItem(category, category.groups[0], item, false);
        this.orderManager.getQuantity(item);
        // TODO: implement virtualscroll
        // this.vscroll.writeUpdate(true);
        return oi;
    };
    // new thing
    ComboPage.prototype.flatten = function () {
        var _this = this;
        var i = 0;
        // let y = 0;
        console.log(this.groups);
        this.autoPickupGroups = [];
        this.nonAutoPickupGroups = [];
        this.itemMap = {};
        console.log('flatten() :isAutoPickup ', this.isAutoPickup);
        var _loop_1 = function (group) {
            var items = [];
            // get all available items of the given group
            var mainItems = this_1.menuManager.getAvailableItems(this_1.store, this_1.category, group, this_1.orderManager.isTakeout());
            group.items = mainItems;
            // let isSet = false;
            // init status for title group
            this_1.isTitleGroupExpand[group.id] = {};
            // make a copy of itemGroupMap with some special value?
            var modifiedItems = [];
            var isAutoPickupGroup = this_1.isAutoPickup && this_1.isAutoPickGroup(group, mainItems);
            if (isAutoPickupGroup) {
                this_1.autoPickupGroups.push(group);
            }
            else {
                this_1.nonAutoPickupGroups.push(group);
            }
            if (mainItems && mainItems.length > 0) {
                mainItems.forEach(function (item, index) {
                    // item['groupIndex'] = i + 1;
                    // put all itemGroupMap in itemMap
                    _this.itemMap[item.id] = item;
                    // if not edit mode and should do auto pickup
                    if (isAutoPickupGroup && !_this.edit) {
                        console.log('auto pick group', group);
                        _this.addClicked(item, group, false, true);
                        // isSet = true;
                    }
                });
                // handle drinks
                modifiedItems = this_1.groupingTitle(group, mainItems);
                // if (this.edit) {
                //         if (!(group.min === group.max && mainItems.length === group.min && this.isAutoPickup) || !this.isAutoPickup) {
                //                 group['isAutopickUp'] = true; // show item
                //         } else {
                //         }
                // } else {
                // if (!this.isGroupCompleted(group) || !this.isAutoPickup) {
                // } else if (group.optional === false) {
                //         // 因為auto pickup 出錯，特定條件下會把item group一些 un-available itemGroupMap 都pickup 起來,应该忽略optional === true 的 item group
                //
                // }
                // }
                items.push.apply(items, modifiedItems);
            }
            if (this_1.preselectItem && !this_1.edit) {
                var preselectItem = [];
                var startIndex = 0;
                var titleIndex = 0;
                var tmpItems = modifiedItems;
                for (var i_1 = 0; i_1 < group.min + 1; i_1++) {
                    // handle for group.min is 0
                    if ((group.min === 0 && i_1 === 0) || (group.min > 0 && i_1 !== group.min)) {
                        preselectItem[i_1] = group.defaultId && this_1.itemMap[group.defaultId] && i_1 === 0 ? this_1.itemMap[group.defaultId] : null; // have default id
                        if (preselectItem[0] && group.repeat) {
                            break;
                        }
                        if (!preselectItem[i_1]) {
                            if (!tmpItems[startIndex]) {
                                tmpItems = this_1.sameTitleItems[this_1.titles[group.id][titleIndex]];
                                startIndex = 0;
                                titleIndex++;
                            }
                            if (tmpItems[startIndex] && group.defaultId === tmpItems[startIndex].id) {
                                startIndex++;
                            }
                            preselectItem[i_1] = tmpItems[startIndex];
                            startIndex = startIndex + 1;
                        }
                        this_1.addOrderItemMap(group, preselectItem[i_1]);
                    }
                }
                if (preselectItem) {
                    // this.addClicked(preselectItem, false,true);
                    for (var i_2 = 0; i_2 <= group.min; i_2++) {
                        if ((group.min === 0 && i_2 === 0) || (group.min > 0 && i_2 !== group.min)) {
                            if (group.repeat) {
                                if (this_1.getQuantity(preselectItem[0], group) < group.max) {
                                    // 获取当前的预选 item 的数量 ,小于可选的最大值才可以添加, 否则有时(数量等于最大值并最大值为1)时执行之后addclicked会清掉之前autoPickUp选中的item
                                    this_1.addClicked(preselectItem[0], group, false, true);
                                    this_1.addOrderItemMap(group, preselectItem[0]);
                                }
                            }
                            else {
                                if (this_1.getQuantity(preselectItem[i_2], group) < group.max) {
                                    // 获取当前的预选 item 的数量 ,小于可选的最大值才可以添加, 否则有时(数量等于最大值并最大值为1)时执行之后addclicked会清掉之前autoPickUp选中的item
                                    this_1.addClicked(preselectItem[i_2], group, false, true);
                                    this_1.addOrderItemMap(group, preselectItem[i_2]);
                                }
                            }
                        }
                    }
                }
            }
            this_1.itemGroupMap[group.id] = items;
            if (this_1.sameTitleItems[group.id]) {
                for (var key in this_1.sameTitleItems[group.id]) {
                    var index = this_1.titles[group.id].indexOf(key);
                    this_1.isTitleGroupExpand[group.id][key] = false;
                    // this.titleGroupClicked(key, index, group.id);
                }
                // for (const key in this.isTitleGroupExpand[group.id]) {
                //     console.log('loop title expand?', this.isTitleGroupExpand);
                //     if (this.isTitleGroupExpand[group.id][key]) {
                //         const index = this.titles[group.id].indexOf(key);
                //         this.hasItemTitleGroup[group.id] = {title: key, index: index};
                //         this.titleGroupClicked(key, index, group.id);
                //     }
                // }
                // init sub item groups
            }
            i++;
        };
        var this_1 = this;
        for (var _i = 0, _a = this.groups; _i < _a.length; _i++) {
            var group = _a[_i];
            _loop_1(group);
        }
        console.log("itemGroupMap", this.itemGroupMap);
    };
    ComboPage.prototype.add = function (item, group, isPreselect) {
        var _this = this;
        if (isPreselect === void 0) { isPreselect = false; }
        var oi;
        var groupIndex = this.getGroupIndex(group);
        if (groupIndex === -1) {
            groupIndex = this.groups.findIndex(function (g) {
                // TODO: is compared by id safe?
                return (g !== null && g !== undefined && g.id === group.id);
            }) + 1;
        }
        var isOptional = group.min === 0;
        if (this.getGroupMode(group) === 'single') {
            var max = group.max;
            var able = true;
            if (max >= 1) {
                var count = this.totalQuantity(groupIndex);
                if (count >= max && max !== 1) {
                    able = false;
                }
            }
            if (max === 1 && !this.has(item, group)) {
                this.builder.clearItems(groupIndex);
            }
            if (able && !this.has(item, group)) {
                this.builder.plusItem(group, item, groupIndex);
                oi = this.builder.build();
            }
            else if (this.has(item, group)) {
                this.discard(item, group);
            }
            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {
                    this.handlingModifier(oi, group, this.itemMap, function () {
                    }, function () {
                        _this.minusClicked(item, group);
                    });
                }
                else if (isOptional) {
                    var realItem = this.itemMap[item.id];
                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, function () {
                        }, function () {
                            _this.minusClicked(item, group);
                        }, true);
                    }
                }
            }
        }
        else if (this.getGroupMode(group) === 'quantity') {
            var max = group.max;
            if (max <= this.totalQuantity(groupIndex)) {
                console.log('max reached');
                return;
            }
            this.builder.plusItem(group, item, groupIndex);
            oi = this.builder.build();
            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {
                    this.handlingModifier(oi, group, this.itemMap, function () {
                    }, function () {
                        var tmpItems = [];
                        for (var _i = 0, _a = oi.groups[groupIndex].items; _i < _a.length; _i++) {
                            var item_1 = _a[_i];
                            for (var i = 0; i < item_1.quantity; i++) {
                                tmpItems.push(_this.itemMap[item_1.id]);
                            }
                        }
                        for (var _b = 0, tmpItems_1 = tmpItems; _b < tmpItems_1.length; _b++) {
                            var item_2 = tmpItems_1[_b];
                            _this.minusClicked(item_2, group);
                        }
                    });
                }
                else if (isOptional) {
                    var realItem = this.itemMap[item.id];
                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, function () {
                        }, function () {
                            _this.minusClicked(item, group);
                        }, true);
                    }
                }
            }
        }
    };
    ComboPage.prototype.titleGroupClicked = function (title, index, groupId, overwrite) {
        // const childNode = document.getElementById('child-' + groupId + '-' + index);
        // if (this.isTitleGroupExpand[groupId]) {
        //     if (overwrite) {
        //         this.isTitleGroupExpand[groupId][title] = overwrite === 1;
        //     } else {
        //         this.isTitleGroupExpand[groupId][title] = !this.isTitleGroupExpand[groupId][title];
        //     }
        //     if (childNode) {
        //         childNode.style.maxHeight = this.isTitleGroupExpand[groupId][title] ? childNode.scrollHeight + 'px' : '0px';
        //     }
        // }
    };
    // item-selected-tap-tips
    ComboPage.prototype.getModifierItemDisplay = function (imap, item) {
        if (item.quantity > 0) {
            var name_1 = item.name;
            if (item.quantity > 1 && item.marker !== 'no-receipt') {
                name_1 = name_1 + ' x ' + item.quantity;
            }
            if (item.marker !== 'no-receipt')
                return name_1;
        }
        else if (imap && item.quantity === 0) {
            var result = null;
            var i = imap[item.id];
            if (i && i.absentId) {
                var absent = imap[i.absentId];
                if (absent) {
                    var name_2 = absent.name;
                    result = name_2;
                }
                else {
                    console.log('absent item not found', i.absentId);
                }
            }
            return result;
        }
        return null;
    };
    ComboPage.prototype.addClicked = function (item, group, detectChanges, isPreselect) {
        if (detectChanges === void 0) { detectChanges = true; }
        if (isPreselect === void 0) { isPreselect = false; }
        // to do
        // this.googleAnalyticEventTrack("add-item", item.name);
        var groupId = group.id;
        if (this.noNeedConfirm && this.noNeedConfirm[groupId])
            delete this.noNeedConfirm[groupId];
        if (this.isSoldout(item))
            return;
        this.add(item, group, isPreselect);
        this.handleModifier();
        if (detectChanges) {
            this.cd.detectChanges();
        }
        console.log('build', this.builder);
        // fixed: 取消選擇後, css 要回到未選擇時的style
        // this.checkingGroupCompleted(this.getItemRealGroupIndex(item));
    };
    ComboPage.prototype.showNotCompletedAlert = function () {
        return __awaiter(this, void 0, void 0, function () {
            var subtitle, confirm, alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        subtitle = this.t.instant('pages.set-select.select-error');
                        confirm = this.t.instant('buttons.ok');
                        return [4 /*yield*/, this.alertController.create({
                                message: subtitle,
                                buttons: [confirm]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    ComboPage.prototype.showQtyAlert = function () {
        return __awaiter(this, void 0, void 0, function () {
            var subtitle, confirm, alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        subtitle = 'QTY must greater than 0';
                        confirm = this.t.instant('buttons.ok');
                        return [4 /*yield*/, this.alertController.create({
                                message: subtitle,
                                buttons: [confirm]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    ComboPage.prototype.presentModifierPopup = function (store, orderItem, group, itemMap, page, handler, showLastOne) {
        return __awaiter(this, void 0, void 0, function () {
            var input, modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        input = { store: store, orderitem: orderItem, group: group, itemMap: itemMap };
                        if (showLastOne) {
                            input['showLastOne'] = showLastOne;
                        }
                        return [4 /*yield*/, this.modalController.create({
                                component: page,
                                componentProps: input,
                                animated: true,
                                showBackdrop: false,
                                backdropDismiss: true,
                                cssClass: 'modal-largescreen modifier-modal ',
                                enterAnimation: popUpAnimation,
                                leaveAnimation: popDrownAnimation
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (confirm) {
                            if (handler) {
                                handler(confirm);
                            }
                        });
                        modal.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    ComboPage.prototype.getSelectedItemNamesModifier = function (group) {
        //    if (this.builder.groups) return ;
        var itemGroup = this.builder.groups.filter(function (_group) {
            if (_group) {
                return _group.id === group.id;
            }
            else {
                return false;
            }
        })[0];
        // if (itemGroup === null) {
        //     return this.t.instant("pages.item-select.no-need");
        //     // return "不需要";
        // }
        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }
        if (!itemGroup) {
            return '';
        }
        var selected = itemGroup.items;
        if (!selected) {
            return '';
        }
        // get modifier
        var firstItem = true;
        var output = '';
        for (var _i = 0, selected_1 = selected; _i < selected_1.length; _i++) {
            var select = selected_1[_i];
            if (!select.mgroups)
                continue;
            for (var _a = 0, _b = select.mgroups; _a < _b.length; _a++) {
                var mgroup = _b[_a];
                if (!mgroup.items)
                    continue;
                for (var _c = 0, _d = mgroup.items; _c < _d.length; _c++) {
                    var item = _d[_c];
                    if (firstItem) {
                        output = item.name + ' x ' + item.quantity;
                        firstItem = false;
                    }
                    else {
                        output = output + ', ' + item.name + ' x ' + item.quantity;
                    }
                }
                // return output;
            }
        }
        return output;
    };
    /*hasModifier(items: Item[], itemMap: any, checkRequired: boolean = false): boolean {
        let required = false;
        for (const item of items) {
            const tmpItem = itemMap[item.id];
            if (tmpItem && tmpItem.mgroups && tmpItem.mgroups.length > 0) {
                if (checkRequired) {
                    for (const mgroup of tmpItem.mgroups) {
                        if (mgroup.min && mgroup.min > 0) {
                            required = true;
                            break;
                        }
                    }
                } else {
                    required = true;
                    break;

                }

            }
        }
        return required;
    }*/
    ComboPage.prototype.isRequiredModifierSelect = function (index) {
        var _this = this;
        var oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        this.currentOrderItem = oi;
        var tmpIndex = index + 1;
        var group = oi.groups[tmpIndex];
        if (group && this.hasRequiredModifier(group.items)) {
            var builderItems = group.items;
            var selected = !builderItems.some(function (item) {
                var builderMgroups = item.mgroups;
                var mgroups = _this.itemMap[item.id].mgroups;
                if (mgroups) {
                    for (var i = 0; i < mgroups.length; i++) {
                        if (builderMgroups && builderMgroups[i] && mgroups[i].min > 0) {
                            var quantity = 0;
                            for (var _i = 0, _a = builderMgroups[i].items; _i < _a.length; _i++) {
                                var t = _a[_i];
                                quantity += 1 * t.quantity;
                            }
                            return !(quantity >= mgroups[i].min);
                        }
                        else if ((!builderMgroups || !builderMgroups[i])) {
                            return mgroups[i].min > 0;
                        }
                    }
                }
                else {
                    return false;
                }
            });
            return selected;
        }
        else {
            return true;
        }
    };
    ComboPage.prototype.isModifiable = function (item) {
        if (!this.modifiableMap[item.id]) {
            this.modifiableMap[item.id] = Item.isModifiable(item);
        }
        return this.modifiableMap[item.id];
    };
    ComboPage.prototype.isRequiredModifier = function (item) {
        if (this.isModifiable(item)) {
            if (!this.requiredModifierMap[item.id]) {
                this.requiredModifierMap[item.id] = item.mgroups.some(function (mgroup) { return mgroup.min > 0; });
            }
            return this.requiredModifierMap[item.id];
        }
        else {
            return false;
        }
    };
    return ComboPage;
}(BaseComboPage));
export { ComboPage };
