<!--
  Generated template for the DineIn page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content padding (click)="dismissClicked()">

    <div class="top-container" (click)="$event.stopPropagation();">
        <p class="ad-title">Free Gift included with your Pizzahut membership!</p>
        <p class="ad-subtitle">*Promotion ended by the end of July</p>
        <img class="ad" src="../assets/images/pizzahut/c3.png"/>
        <p class="ad-title">Free Original Chicken Wings</p>
        <ion-button class="close-btn" (click)="dismissClicked()">
            <ion-icon name="ios-close" slot="icon-only" color="dark"></ion-icon>
        </ion-button>
        <ion-button class="reg-btn" round color="success" (click)="registration()">
            {{'pages.login.register'|translate}}
        </ion-button>
    </div>

</ion-content>
