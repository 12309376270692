/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passcode.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./passcode";
var styles_PasscodeDialog = [i0.styles];
var RenderType_PasscodeDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_PasscodeDialog, data: {} });
export { RenderType_PasscodeDialog as RenderType_PasscodeDialog };
function View_PasscodeDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "animated fadeInUps"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("pages.splash.errorPasscode")); _ck(_v, 1, 0, currVal_0); }); }
export function View_PasscodeDialog_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), i1.ɵqud(402653184, 2, { myInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 27, "ion-content", [["padding", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.dismissClicked();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonContent_0, i3.RenderType_IonContent)), i1.ɵdid(3, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(4, 16384, null, 0, i4.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 24, "div", [["class", "custom-dialog"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ion-icon", [["class", "close-icon"], ["name", "ios-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(7, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "text-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 12, "div", [["class", "input-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 8, "ion-input", [["autofocus", ""], ["maxlength", "8"], ["name", "passcode"], ["type", "tel"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16)._handleInputEvent($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.passcode = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_IonInput_0, i3.RenderType_IonInput)), i1.ɵdid(14, 540672, null, 0, i5.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i5.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i5.MaxLengthValidator]), i1.ɵdid(16, 16384, null, 0, i4.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.TextValueAccessor]), i1.ɵdid(18, 671744, null, 0, i5.NgModel, [[8, null], [6, i5.NG_VALIDATORS], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(20, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), i1.ɵdid(21, 49152, [[2, 4], ["input", 4]], 0, i4.IonInput, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { autofocus: [0, "autofocus"], maxlength: [1, "maxlength"], name: [2, "name"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "error-text-box"], ["color", "danger"], ["ion-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasscodeDialog_1)), i1.ɵdid(24, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 4, "div", [["class", "btn-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 3, "ion-button", [["class", "confirm-btn"], ["fill", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(27, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"] }, null), (_l()(), i1.ɵted(28, 0, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ios-close"; _ck(_v, 7, 0, currVal_0); var currVal_10 = "8"; _ck(_v, 14, 0, currVal_10); var currVal_11 = "passcode"; var currVal_12 = _co.passcode; _ck(_v, 18, 0, currVal_11, currVal_12); var currVal_13 = ""; var currVal_14 = "8"; var currVal_15 = "passcode"; var currVal_16 = "tel"; _ck(_v, 21, 0, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_17 = _co.wrong; _ck(_v, 24, 0, currVal_17); var currVal_18 = "clear"; _ck(_v, 27, 0, currVal_18); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("pages.splash.enterPasscode")); _ck(_v, 10, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 14).maxlength ? i1.ɵnov(_v, 14).maxlength : null); var currVal_3 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 20).ngClassValid; var currVal_8 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_19 = i1.ɵunv(_v, 28, 0, i1.ɵnov(_v, 29).transform("buttons.confirm")); _ck(_v, 28, 0, currVal_19); }); }
export function View_PasscodeDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-passcode", [], null, null, null, View_PasscodeDialog_0, RenderType_PasscodeDialog)), i1.ɵdid(1, 245760, null, 0, i7.PasscodeDialog, [i1.Injector, i4.NavParams], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PasscodeDialogNgFactory = i1.ɵccf("page-passcode", i7.PasscodeDialog, View_PasscodeDialog_Host_0, {}, {}, []);
export { PasscodeDialogNgFactory as PasscodeDialogNgFactory };
