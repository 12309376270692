import {Component, OnInit, Injector} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'is-login-member',
    templateUrl: 'is-login-member.html',
    styleUrls: ['is-login-member.scss']
})
export class IsLoginMemberDialogs extends MobileBasePage implements OnInit {

    ok: () => any;
    cancel: () => any;
    constructor(private injector: Injector, public modalController: ModalController) {
        super(injector);
        this.ok = this.getNavParams('ok');
        this.cancel = this.getNavParams('cancel');
    }

}
