/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reward-list.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./reward-list";
var styles_RewardListDialog = [i0.styles];
var RenderType_RewardListDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_RewardListDialog, data: {} });
export { RenderType_RewardListDialog as RenderType_RewardListDialog };
function View_RewardListDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-avatar", [["slot", "start"]], null, null, null, i2.View_IonAvatar_0, i2.RenderType_IonAvatar)), i1.ɵdid(1, 49152, null, 0, i3.IonAvatar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.ngIf, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_RewardListDialog_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "stamp-cost"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "ion-button", [["color", "success"], ["fill", "outline"], ["slot", "end"]], null, null, null, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(5, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], fill: [1, "fill"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "success"; var currVal_3 = "outline"; _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.cost; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform("pages.reward-list.stamps-req")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("global.redeem")); _ck(_v, 6, 0, currVal_4); }); }
function View_RewardListDialog_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "insuff-stamp-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "/", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Need ", " more"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.memberService == null) ? null : ((_co.memberService.member == null) ? null : ((_co.memberService.member.membership == null) ? null : _co.memberService.member.membership.stamps))); var currVal_1 = _v.parent.context.$implicit.cost; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.cost - ((_co.memberService == null) ? null : ((_co.memberService.member == null) ? null : ((_co.memberService.member.membership == null) ? null : _co.memberService.member.membership.stamps)))); _ck(_v, 4, 0, currVal_2); }); }
function View_RewardListDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ion-item", [["class", "cardShadow AQA-reward-item"], ["no-lines", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRewardClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "unavailable": 0, "animated shake": 1 }), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "stamp-info-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RewardListDialog_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(8, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "div", [["class", "stamp-info-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RewardListDialog_3)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["notEnough", 2]], null, 0, null, View_RewardListDialog_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "cardShadow AQA-reward-item"; var currVal_1 = _ck(_v, 3, 0, (!_co.isAvailableReward(_v.context.$implicit) || !_co.canRedeem(_v.context.$implicit)), _co.isBounce); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.getImage(_v.context.$implicit); _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.canRedeem(_v.context.$implicit); var currVal_5 = i1.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_3); }); }
export function View_RewardListDialog_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(2, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "div", [["class", "list-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ion-button", [["fill", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "ion-icon", [["color", "dark"], ["name", "md-close"], ["slot", "icon-only"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(10, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 6, "ion-content", [["class", "content"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(12, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 4, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "ion-list", [["class", "couponBS-wrapper"]], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(15, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RewardListDialog_1)), i1.ɵdid(17, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "clear"; _ck(_v, 8, 0, currVal_1); var currVal_2 = "dark"; var currVal_3 = "md-close"; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = _co.rewards; _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("pages.reward-list.title")); _ck(_v, 5, 0, currVal_0); }); }
export function View_RewardListDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-reward-list", [], null, null, null, View_RewardListDialog_0, RenderType_RewardListDialog)), i1.ɵdid(1, 245760, null, 0, i6.RewardListDialog, [i1.Injector, i3.NavParams], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RewardListDialogNgFactory = i1.ɵccf("page-reward-list", i6.RewardListDialog, View_RewardListDialog_Host_0, {}, {}, []);
export { RewardListDialogNgFactory as RewardListDialogNgFactory };
