import {UUID} from 'angular2-uuid';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Session} from '../data/session';

@Injectable({providedIn: 'root'})
export class SessionService {

    session: Session;

    constructor(private configService: ConfigService) {

        // restore session here
        this.session = configService.getLocal('byod.session');


    }

    getSession(): Session {

        return this.session;

    }

    getTodaySession(): Session {

        const session = this.getSession();
        if (!session) {
            return null;
        }
        /*
        var today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        if(today == session.date){
            return session;
        }*/

        return null;

    }

    setSession(session) {

        this.session = session;

        this.configService.setLocal('byod.session', session);

    }

    getDeviceId() {

        let did = this.configService.getLocal('deviceId');
        if (!did) {
            did = UUID.UUID().toString();
            this.configService.setLocal('deviceId', did);
        }

        return did;
    }


}
