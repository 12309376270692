import {NgModule} from '@angular/core';
import {ThrottleClickDirective} from './throttle-click';

@NgModule({
    declarations: [
        ThrottleClickDirective,
    ],
    imports: [],
    exports: [
        ThrottleClickDirective,
    ]
})
export class ThrottleClickModule {
}
