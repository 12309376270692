var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SdkBaseService } from './sdk-base-service';
import * as i0 from "@angular/core";
var SdkGooglePayService = /** @class */ (function (_super) {
    __extends(SdkGooglePayService, _super);
    function SdkGooglePayService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SdkGooglePayService.prototype.getServiceName = function () {
        return 'GooglePayService';
    };
    /**
     *
     * @param params
     * {
            items: [
                {
                    label: '3 x Basket Items',
                    amount: 49.99
                },
                {
                    label: 'Next Day Delivery',
                    amount: 3.99
                },
                {
                    label: 'My Fashion Company',
                    amount: 53.98
            }],

            shippingMethods: [
                {
                    identifier: 'NextDay',
                    label: 'NextDay',
                    detail: 'Arrives tomorrow by 5pm.',
                amount: 3.99
                },
                {
                    identifier: 'Standard',
                    label: 'Standard',
                    detail: 'Arrive by Friday.',
                    amount: 4.99
                },
                {
                    identifier: 'SaturdayDelivery',
                    label: 'Saturday',
                    detail: 'Arrive by 5pm this Saturday.',
                    amount: 6.99
            }],

            merchantIdentifier: 'merchant.apple.test',  // Need to pass?

            currencyCode: 'HKD',  /// CNY    HKD
            countryCode: 'HK',  ///  CN    HK
            billingAddressRequirement: 'none', // none/all/postcode/email/phone
            shippingAddressRequirement: 'none', // none/all/postcode/email/phone
            shippingType: 'shipping'  // shipping/delivery/store/service
        }
     *
     * @param callback :required
     *
     *   {
            "paymentData": "<BASE64 ENCODED TOKEN WILL APPEAR HERE>",
            "transactionIdentifier": "Simulated Identifier",
            "paymentMethodDisplayName": "MasterCard 1234",
            "paymentMethodNetwork": "MasterCard",
            "paymentMethodTypeCard": "credit",
            "billingEmailAddress": "",
            "billingSupplementarySubLocality": "",
            "billingNameFirst": "First",
            "billingNameMiddle": "",
            "billingNameLast": "NAME",
            "billingAddressStreet": "Street 1\n",
            "billingAddressCity": "London",
            "billingAddressState": "London",
            "billingPostalCode": "POST CODE",
            "billingCountry": "United Kingdom",
            "billingISOCountryCode": "gb",
            "shippingEmailAddress": "",
            "shippingPhoneNumber": "",
            "shippingNameFirst": "First",
            "shippingNameMiddle": "",
            "shippingNameLast": "Name",
            "shippingSupplementarySubLocality": "",
            "shippingAddressStreet": "Street Line 1\nStreet Line 2",
            "shippingAddressCity": "London",
            "shippingAddressState": "London",
            "shippingPostalCode": "POST CODE",
            "shippingCountry": "United Kingdom",
            "shippingISOCountryCode": "gb",
        }
     */
    SdkGooglePayService.prototype.makePaymentRequest = function (params, callback) {
        // if (!this.isAvailable()) return;
        console.log(this.getServiceName());
        this.callNative('makePaymentRequest', params, callback);
    };
    /**
     *
     * @param params :
        * { paymentAuthorizationStatus: "success" / "failure" / "invalid-billing-address" / "invalid-shipping-address" / "invalid-shipping-contact" / "require-pin" / "incorrect-pin" / "locked-pin"  }
     *
     *
     * @param callback ,optional
     */
    SdkGooglePayService.prototype.completeLastTransaction = function (params, callback) {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('completeLastTransaction', params, callback);
    };
    SdkGooglePayService.ngInjectableDef = i0.defineInjectable({ factory: function SdkGooglePayService_Factory() { return new SdkGooglePayService(); }, token: SdkGooglePayService, providedIn: "root" });
    return SdkGooglePayService;
}(SdkBaseService));
export { SdkGooglePayService };
