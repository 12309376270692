<!-- <ion-header>
    <div class="list-header">
        <h1>Title</h1>
        <ion-button fill="clear" (click)="dismissClicked()">
            <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
        </ion-button>
    </div>
    <div class="tag-bar-wrapper">
        <div class="tag-bar">
            <div *ngFor="let item of modifiableItems;let i = index" (click)="selectItem(item,i)"
                [class.active]="i == currentIndex" tappable>{{item.name}} x{{itemsCount[item.id]}}</div>
        </div>
    </div>
</ion-header> -->
<div  (click)="dismissClicked()" [ngStyle]="{'height': '100vh'}">
    <div class="combo-wrapper fix-bottom widthMax" (click)="$event.stopPropagation();">

        <div class="list-header-wrapper">
            <div class="list-header">
                <span>{{group?.name}}</span>
                <ion-button fill="clear" (click)="dismissClicked()">
                    <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
                </ion-button>
            </div>
        </div>

        <div class="tag-bar-wrapper">
            <div class="tag-bar">
                <!-- <div *ngFor="let item of modifiableItems;let i = index" (click)="selectItem(item,i)"
                    [class.active]="i == currentIndex" tappable>{{item.name}} x{{itemsCount[item.id]}}</div> -->

                    <!-- Scrollable Segment -->
                <ion-segment mode="md" scrollable [value]="currentIndex" (ionChange)="mgroupSegmentChanged($event)">
                    <ion-segment-button *ngFor="let item of modifiableItems;let i = index;" [value]="i" [ngClass]= "{'segment-button-checked': i == currentIndex}">
                        <!-- <ion-label>{{item.name}} x{{itemsCount[item.id]}}</ion-label> -->
                        <ion-label>{{item.name}}</ion-label>
                    </ion-segment-button>
                </ion-segment>

            </div>
        </div>

        <div class="combo-info-contain">

            <div class="content-box" (click)="$event.stopPropagation()" id="content-top">

                <div class="item-content-wrapper animated fadeInRights">
                    <div class="div-padding">
                        <div *ngFor="let mgroup of currentItem.mgroups" class="item-content">
                            <div class="modi-item-title">
                                <p class="ig-title" *ngIf="!settings?.hideModIgName">{{mgroup.name}}</p>
                                <div class="ig-modifier-request-box" *ngIf="getSelectRules(mgroup)"
                                    [ngClass]="{'modifier-selected': isGroupSelected(currentItem, mgroup) }">
                                    <ion-icon name="checkmark" color="medium" class="checkmark-icn"></ion-icon>
                                    <p class="ig-modifier-request">{{getSelectRules(mgroup)}}</p>
                                </div>
                            </div>

                            <div class="modifier-item-wrapper">

                                <!-- separate the mgroup.items -->
                                <ng-container *ngIf="mgroupItemsMap[mgroup.id]&&mgroupItemsMap[mgroup.id].length>0">

                                    <div class="separate-mgroup-item-box"
                                        *ngFor="let separateItems of mgroupItemsMap[mgroup.id]">
                                        <div class="msgroup-title"  *ngIf="separateItems[0].mgroup">{{separateItems[0].mgroup}}</div>
                                        <div class="msgroup-choices">
                                            <div lines="none" class="modifier-item-box ion-text-wrap" *ngFor="let item of separateItems"
                                                tappable (click)="itemClicked(currentItem, mgroup, item)"
                                                [hidden]="absentItemsId.indexOf(item.id) != -1"
                                                [ngClass]="{'modi-selected': has(currentItem, mgroup, item), 'is-soldout-box': isSoldout(item)}">
                                                <div slot="start" class="modi-no-qty" *ngIf="!mgroup.repeat">
                                                    <p>{{item.name}} <ng-container *ngIf="item.price != 0">
                                                            +{{orderManager?.store?.currencySign}}{{item.price}}
                                                        </ng-container>
                                                    </p>
                                                </div>
                                        

                                            <!-- with quantity -->
                                            <ng-container *ngIf="item.baseQty > 0 || mgroup.repeat">

                                                <!-- when the mgroup.max > 1  show the number tips -->
                                                <ng-container *ngIf="mgroup.max > 1">
                                                    <div class="item-w-price">

                                                        <!-- minus -->
                                                        <div class="div-qty-btn div-remove-qty-btn">
                                                            <!-- <ng-container *ngIf="has(currentItem, mgroup, item)"> -->
                                                            <div (click)="minusClicked(currentItem, mgroup, item);$event.stopPropagation();"
                                                                class="btn-minus">
                                                                <ion-icon slot="icon-only" name="remove-circle"
                                                                    *ngIf="item.min != item.max && item.max != 0">
                                                                </ion-icon>
                                                            </div>
                                                            <!-- </ng-container> -->
                                                        </div>

                                                        <p [ngClass]="{'noPrice': item.price == 0}">{{item.name}}</p>


                                                        <p *ngIf="item.price != 0">
                                                            +{{orderManager?.store?.currencySign}}{{item.price}}
                                                        </p>

                                                        <div class="qty-w-minus"
                                                            [ngClass]="{'btn-added': has(currentItem, mgroup, item)}">

                                                            <div class="add-symbol">
                                                                <ion-icon name="add-circle"></ion-icon>
                                                            </div>

                                                            <div class="div-qty-text">
                                                                <p class="qty-text">
                                                                    x{{builder.getQuantity(currentItem,mgroup, item) || 0}}
                                                                </p>
                                                            </div>

                                                            <div>
                                                                <!-- <ion-button fill="clear" size="small" class="qty-btn add-qty-btn">
                                                                                                                        <ion-icon slot="icon-only" name="md-add-circle-outline" color="{{ (builder.getQuantity(currentItem,mgroup, item) >= item.max)?'medium':'success'}}">
                                                                                                                                    </ion-icon>
                                                                                                                                </ion-button> -->
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-container>

                                                <!-- when the mgroup.max == 1 not need the number tips-->
                                                <ng-container *ngIf="mgroup.max == 1">
                                                    <div class="item-w-price">
                                                        <p [ngClass]="{'noPrice': item.price == 0}">{{item.name}}</p>

                                                        <p *ngIf="item.price != 0">
                                                            +{{orderManager?.store?.currencySign}}{{item.price}}
                                                        </p>
                                                    </div>

                                                </ng-container>

                                            </ng-container>

                                        </div>
                                    </div>
                                </div>

                                </ng-container>

                                <ng-container
                                    *ngIf="!mgroupItemsMap[mgroup.id]||(mgroupItemsMap[mgroup.id]&&mgroupItemsMap[mgroup.id].length==0)">
                                    <div lines="none" class="modifier-item-box ion-text-wrap" *ngFor="let item of mgroup.items" tappable
                                        (click)="itemClicked(currentItem, mgroup, item)"
                                        [hidden]="absentItemsId.indexOf(item.id) != -1"
                                        [ngClass]="{'modi-selected': has(currentItem, mgroup, item), 'is-soldout-box': isSoldout(item)}">



                                        <!-- <div class="sold-out-box" (click)="$event.stopPropagation()" *ngIf="item.min == 0 && item.max == 0">
                                            <p>{{"pages.item-grid.soldout" | translate}} </p>
                                        </div> -->

                                        <!-- without quantity -->
                                        <ng-container *ngIf="item.baseQty <= 0 && !mgroup.repeat">
                                            <div slot="start" class="modi-no-qty">
                                                <!-- <ion-icon
                                                    name="{{has(currentItem, mgroup, item) ? 'checkmark-circle' : 'radio-button-off'}}"
                                                    color="success"></ion-icon> -->

                                                <p>{{item.name}} <ng-container *ngIf="item.price != 0">
                                                        +{{orderManager?.store?.currencySign}}{{item.price}}</ng-container>
                                                </p>

                                            </div>
                                        </ng-container>

                                        <!-- with quantity -->
                                        <ng-container *ngIf="item.baseQty > 0 || mgroup.repeat">

                                            <!-- when the mgroup.max > 1  show the number tips -->
                                            <ng-container *ngIf="mgroup.max > 1">
                                                <div class="item-w-price">

                                                    <!-- minus -->
                                                    <div class="div-qty-btn div-remove-qty-btn">
                                                        <!-- <ng-container *ngIf="has(currentItem, mgroup, item)"> -->
                                                        <div (click)="minusClicked(currentItem, mgroup, item);$event.stopPropagation();"
                                                            class="btn-minus">
                                                            <ion-icon slot="icon-only" name="remove-circle"
                                                                *ngIf="item.min != item.max && item.max != 0">
                                                            </ion-icon>
                                                        </div>
                                                        <!-- </ng-container> -->
                                                    </div>

                                                    <p [ngClass]="{'noPrice': item.price == 0}">{{item.name}}</p>


                                                    <p *ngIf="item.price != 0">
                                                        +{{orderManager?.store?.currencySign}}{{item.price}}
                                                    </p>

                                                    <div class="qty-w-minus"
                                                        [ngClass]="{'btn-added': has(currentItem, mgroup, item)}">

                                                        <div class="add-symbol">
                                                            <ion-icon name="add-circle"></ion-icon>
                                                        </div>

                                                        <div class="div-qty-text">
                                                            <p class="qty-text">
                                                                x{{builder.getQuantity(currentItem,mgroup, item) || 0}}</p>
                                                        </div>

                                                        <div>
                                                            <!-- <ion-button fill="clear" size="small" class="qty-btn add-qty-btn">
                                                                                                                            <ion-icon slot="icon-only" name="md-add-circle-outline" color="{{ (builder.getQuantity(currentItem,mgroup, item) >= item.max)?'medium':'success'}}">
                                                                                                                            </ion-icon>
                                                                                                                        </ion-button> -->
                                                        </div>
                                                    </div>

                                                </div>
                                            </ng-container>

                                            <!-- when the mgroup.max == 1 not need the number tips-->
                                            <ng-container *ngIf="mgroup.max == 1">
                                                <div class="item-w-price">
                                                    <p [ngClass]="{'noPrice': item.price == 0}">{{item.name}}</p>

                                                    <p *ngIf="item.price != 0">
                                                        +{{orderManager?.store?.currencySign}}{{item.price}}
                                                    </p>
                                                </div>

                                            </ng-container>

                                        </ng-container>
                                    </div>
                                </ng-container>

                            </div>
                        </div>

                    </div>

                </div>



            </div>

        </div>

        <div class="btn-box animated fadeInUps">
            <ng-container *ngFor="let i of selectItems">
                <p *ngIf="i&&i.length>0" class="item-selected-tap">
                    <!-- need to prefect the display conditions of span-el -->
                    <!-- here -->
                    <!-- <ng-container *ngIf="i && i.length> 0"> -->
                    <span *ngFor="let m of i;let a = index;" class="item-selected-tap-tips">{{m}}
                        <i *ngIf="a<i.length-1">,</i>
                    </span>
                    <!-- </ng-container> -->
                    <!-- Change to Dry Aged Patty x1, Add Beef Patty x2, Add Chicken x1 -->
                </p>
            </ng-container>

            <div class="confirm-btn" [ngClass]="{'completed flashit': isAllCompleted(modifiableItems)}"
                (click)="confirmClicked()">
                <p>{{"pages.variation.confirm" | translate}}</p>
            </div>
        </div>

    </div>
</div>
