<!-- <ion-header [ngClass]="{'address-dialog-modal': !isRoute, 'address-dialog-route': isRoute}">
  <ion-header> -->
<div class="toolbar-container">
  <div class="close-btn">
    <ion-icon name="md-close" (click)="closeClicked()"></ion-icon>
  </div>

  <!-- <ion-title>{{'dialog.address-dialog.title'|translate}}</ion-title> -->
</div>

<ion-content [ngClass]="{'address-dialog-modal': !isRoute, 'address-dialog-route': isRoute}">

  <!-- 配送地址 -->
  <div class="content-container">

    <div class="title-container">
      <div class="title-address">
        <div class="title-image">
          <img src="assets/images/motorcycle.svg">
        </div>
        <span>{{'dialog.address-dialog.title'|translate}}</span>
      </div>
      <div class="remove-btn" (click)="removeHandle()" *ngIf="!isRemove && addressArr.length > 0">
        <p>{{'dialog.address-dialog.remove'|translate}}</p>
      </div>
    </div>

    <!-- when add-handle show  and the radio only select the one -->
    <ion-list *ngIf="!isRemove">
      <!-- allow-empty-selection  (ionChange)="selectAddressChange($event)" -->
      <ion-radio-group [(ngModel)]="currentAddress"   (click)="setPickupAddressNull()" *ngIf="addressArr.length > 0">

        <div class="dialog-address ">
          <ion-item class="address-too-far"h
            *ngFor="let address of addressArr;let i = index; trackBy:trackAddressById"
            >
            <div class="address-click-btn">
              <ion-radio slot="start" [value]="address" mode="md"></ion-radio>
            </div>
            <div class="showed-address-wrapper">
              <p class="address-name">{{address.name}}</p>
              <p class="address-full">{{address.full}}</p>
              <p class="address-phone">{{'dialog.address-dialog.label-mobile'|translate}}: {{address.phone}}</p>
              <div *ngIf="address['beyond']" class="too-far-row">
                <div class="too-far-icon">
                  <ion-icon name="md-alert"></ion-icon>
                </div>
                <p>Delivery service <b>DOES NOT</b> cover this location, but you can still view the menu by selecting
                  this location.</p>
              </div>
            </div>
          </ion-item>
        </div>
      </ion-radio-group>


      <div class="add-address-column" (click)="addNewAdress();$event.stopPropagation;">
        <div class="add-address-btn">
          <ion-icon name="ios-add"></ion-icon>
        </div>
        <div class="add-address-text">
          <p>{{'dialog.address-dialog.add-new-address'|translate}}</p>
        </div>
      </div>

    </ion-list>

    <!-- when remove-handle show  and the radio can select one or more-->
    <ion-list *ngIf="addressArr.length > 0 && isRemove">
      <ion-item *ngFor="let address of addressArr;let i = index; trackBy:trackAddressById" (click)="removeAddressArrChange(i);$event.stopPropagation;">
        <div class="address-remove-checkbox">
          <ion-checkbox color="primary"></ion-checkbox>
        </div>
        <div class="showed-address-wrapper">
          <p class="address-name">{{address.name}}</p>
          <p class="address-full">{{address.full}}</p>
          <p class="address-phone">{{'dialog.address-dialog.label-mobile'|translate}}: {{address.phone}}</p>
        </div>
      </ion-item>
    
    </ion-list>
  </div>

  <!-- pickup at address -->
  <div class="content-container" *ngIf="pickupAddressGroups.length > 0 && !isRemove">

    <div class="title-container">
      <div class="title-address">
        <div class="title-image pickup">
          <img src="assets/images/pickup.svg">
        </div>
        <span>{{'dialog.address-dialog.pick-up-title'|translate}}</span>
      </div>
    </div>

    <!-- pick up item -->
    <ion-list>
      <ion-radio-group [(ngModel)]="pickupAddress" (click)="setCurrentAddressNull()">
          <ion-item class="address-too-far" h
            *ngFor="let address of pickupAddressGroups;let i = index; trackBy:trackAddressById">
            <div class="address-click-btn">
              <ion-radio slot="start" [value]="address" mode="md"></ion-radio>
            </div>
            <div class="showed-address-wrapper">
              <p class="address-name">{{address.name}}</p>
              <p class="address-full">{{address.full}}</p>
              <p class="address-phone">{{'dialog.address-dialog.label-mobile'|translate}}: {{address.phone}}</p>
            </div>
          </ion-item>
      </ion-radio-group>
  
    </ion-list>
  </div>
</ion-content>

<ion-footer [ngClass]="{'address-dialog-modal': !isRoute, 'address-dialog-route': isRoute}">

  <!-- confirm -->
  <div class="btn-box" *ngIf="(currentAddress || this.addressManagerService.isPickup) && !isRemove">
    <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button" (click)="okClicked()">
      {{'dialog.address-dialog.confirm'|translate}}
    </ion-button>
  </div>

  <!-- remove -->
  <div class="btn-box" *ngIf="isRemove">
    <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button" (click)="removeClicked()">
      {{'dialog.address-dialog.remove'|translate}}
    </ion-button>
  </div>

</ion-footer>