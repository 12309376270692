import {Component, Injector, OnInit} from '@angular/core';
import {Offer} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';
import {NavParams} from '@ionic/angular';
import {isAvailableOffer} from '../../shared/utilities/checkFormatTool';


@Component({
    selector: 'page-offer-list',
    templateUrl: 'offer-list.html',
    styleUrls: ['offer-list.scss']
})
export class OfferListDialog extends MobileBasePage implements OnInit {

    offers: Offer[] = [];
    isBounce = false;

    constructor(public injector: Injector, public navParams: NavParams) {

        super(injector);

        this.offers = this.navParams.get('offers');
    }


    ngOnInit() {
        console.log('ngOnInit VariationPage');
    }


    dismissClicked() {
        this.modalController.dismiss(null);

    }

    bounce() {
        this.isBounce = true;
        setTimeout(() => {
            this.isBounce = false;
        }, 1000);
    }
    isAvailableReward(offer)  {
        return isAvailableOffer(offer);
    }
    clickReward(offer: Offer) {
        if (isAvailableOffer(offer)) {
            this.modalController.dismiss(offer);
            this.bounce();
        }
    }


}
