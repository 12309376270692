var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { Injector, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
/*
  Generated class for the TermsConditions page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
var TermsConditionsPage = /** @class */ (function (_super) {
    __extends(TermsConditionsPage, _super);
    // constructor(public viewCtrl: ViewController, injector: Injector, params: NavParams,public startupService: StartupService
    function TermsConditionsPage(injector, params, storage) {
        var _this = _super.call(this, injector) || this;
        _this.storage = storage;
        _this.isOffline = false;
        _this.isAgree = false;
        _this.marketing = true;
        _this.needToAgreeMarketing = false;
        _this.input = {};
        var locale = _this.t.currentLang;
        /*if (locale == "zh") {
            this.contentUrl = "https://storage.googleapis.com/aigensstoretest.appspot.com/70000/ssptnc.html";
        } else {
            this.contentUrl = "https://storage.googleapis.com/aigensstoretest.appspot.com/70000/ssptnc.html";
        }*/
        _this.contentUrl = params.get('url');
        _this.needToAgreeMarketing = params.get('needToAgreeMarketing');
        _this.fbHandler = params.get('fbHandler');
        _this.input['name'] = params.get('brandName');
        return _this;
    }
    TermsConditionsPage.prototype.ngOnInit = function () {
    };
    TermsConditionsPage.prototype.ionViewDidEnter = function () {
        this.loadContentApi();
        // this.getLangWithData();
    };
    TermsConditionsPage.prototype.getLangWithData = function () {
        var _this = this;
        this.loading(true);
        this.storage.ready().then(function () {
            _this.storage.get('languageMode').then(function (lange) {
                if (lange) {
                    _this.loadContentApi(lange);
                }
                else {
                    _this.loadContentApi();
                }
            }).catch(function (err) {
                _this.loading(false);
            });
        }).catch(function (err) {
            _this.loading(false);
        });
    };
    TermsConditionsPage.prototype.loadContentApi = function (lange) {
        if (!this.isOffline) {
            var iframe = document.getElementById('iframe');
            iframe.setAttribute('src', this.contentUrl);
        }
    };
    TermsConditionsPage.prototype.clickMarketing = function () {
        this.marketing = !this.marketing;
    };
    TermsConditionsPage.prototype.dismiss = function (agree) {
        console.log('dismiss');
        if (agree) {
            if (this.fbHandler) {
                this.fbHandler({ agree: agree, marketing: this.marketing });
            }
            this.modalController.dismiss({ agree: agree, marketing: this.marketing });
        }
        else {
            this.modalController.dismiss({ fail: 'failed' });
        }
        // this.push(BrandStoreListPage, {brand:5637145314197504});
    };
    TermsConditionsPage.prototype.reload = function () {
        this.loadContentApi();
        // this.getLangWithData();
    };
    return TermsConditionsPage;
}(MobileBasePage));
export { TermsConditionsPage };
