<div  (click)="dismissClicked()" [ngStyle]="{'height': '100vh'}">
    <div class="variation-wrapper" (click)="$event.stopPropagation()">
        <div class="list-header-wrapper">
            <div class="list-header">
                <span>{{title}}</span>
                <ion-button fill="clear" (click)="dismissClicked()">
                    <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
                </ion-button>
            </div>
        </div>
        <div class="content" (click)="dismissClicked()">
    
            <div class="content-box">
    
                <div class="couponBS-wrapper animated fadeInRights">
    
                    <ion-item *ngFor="let item of items; let i = index" (click)="itemClicked(i);$event.stopPropagation()" class="item-box">
                        <div class="sold-out-box AQA-soldOut" *ngIf="isSoldout(item)" item-content>
                            <span>{{"pages.item-grid.soldout" | translate}}</span>
                        </div>
                        <!--<ion-radio></ion-radio>-->
    
                        <ion-label class="item-info">
                            <h2 class="itemName-text">{{item.name}}</h2>
                            <p class="itemDesc-text" *ngIf="!settings?.hideIgName">{{getModifierString(item)}}</p>
                            <span class="itemPrice-text">{{orderManager?.store?.currencySign}}
                                {{item|itemPrice}}</span>
                        </ion-label>
    
                        <ion-thumbnail *ngIf="item.images && item.images['default']" slot="end">
                            <img src="{{item.getImage('default', 100)}}">
                        </ion-thumbnail>
    
                        <!-- <ion-checkbox color="success" slot="end" [checked]="selectedIndex === i"></ion-checkbox> -->
                    </ion-item>
    
                </div>
            </div>
        </div>
    
        <!-- <div class="div-footer">
            <div class="btn-box">
                <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button"
                    [class.confirmed]="selectedIndex >= 0" (click)="okClicked()">
                    {{"pages.variation.confirm" | translate}}
                </ion-button>
            </div>
        </div> -->
    
    </div>

</div>
