var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TermsConditionsPage } from '../../../dialogs/terms-conditions/terms-conditions';
import { MemberService } from '../../../core/services/member.service';
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { getCountryCallingCode } from '../../../shared/utilities/checkFormatTool';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
var DeliveryLoginPage = /** @class */ (function (_super) {
    __extends(DeliveryLoginPage, _super);
    function DeliveryLoginPage(injector, memberService) {
        var _this = _super.call(this, injector) || this;
        _this.memberService = memberService;
        _this.form = {};
        _this.status = '';
        _this.googleLoginClicked = false;
        _this.mode = 'email';
        _this.hasCrmId = false;
        _this.isPopup = false;
        _this.facebookLogin = false;
        _this.googleLogin = false;
        _this.loginCallBack = _this.getNavParams('logincb');
        _this.store = _this.getNavParams('store');
        _this.fromPage = _this.getNavParams('fromPage');
        if (!_this.store) {
            // when this component is using for modal-pop-up ,it can be injector NavParams;otherwise be err;
            _this.navParams = injector.get(NavParams);
            _this.store = _this.navParams.get('store');
        }
        if (_this.orderManager.mode === 'delivery' && !_this.getNavParams('isRoute')) {
            //   when this component is not using for modal-pop-up , the next code will be err;
            _this.isPopup = _this.navParams.get('isPopup');
        }
        _this.brand = _this.store.brand;
        _this.memberService.clearSession();
        if (_this.brand) {
            // this.brand['crmId'] = ""
            _this.hasCrmId = _this.brand['crmId'] ? true : false;
            _this.facebookLogin = _this.brand.hasFeature('flogin');
            _this.googleLogin = _this.brand.hasFeature('glogin');
        }
        if (_this.googleLogin) {
            _this.initGoogle();
            _this.googleLoginHandler = function (e) {
                var data = e.detail;
                _this.googleLoginHandling(data.id, data.token);
            };
            window.addEventListener('google-login-handling', _this.googleLoginHandler);
        }
        return _this;
        // console.log('store:', this.store);
    }
    DeliveryLoginPage.prototype.dismissClicked = function () {
        console.log('delivery login modal closing');
        this.modalController.dismiss();
    };
    DeliveryLoginPage.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    DeliveryLoginPage.prototype.getCountryCallingCode = function () {
        return getCountryCallingCode();
    };
    DeliveryLoginPage.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        window.removeEventListener('google-login-handling', this.googleLoginHandler);
    };
    DeliveryLoginPage.prototype.submitClicked = function () {
        var _this = this;
        console.log('login clicking', this.form);
        var username = (this.form[this.mode] ? this.form[this.mode] : '');
        var password = (this.form['password'] ? this.form['password'] : '');
        var countryCallingCode = this.form['countryCallingCode'];
        var tmpSet = new Set(username);
        var type = tmpSet.has('@') ? 'email' : 'phone';
        if (!countryCallingCode && type === 'phone') {
            countryCallingCode = getCountryCallingCode();
        }
        var brandId = null;
        if (this.hasCrmId) {
            if (!this.brand || !this.brand.id) {
                this.showAlert(null, 'Missing Brand ID');
                return;
            }
            if (!username) {
                this.showAlert(null, this.t.instant('pages.login.usernameEmpty'));
                return;
            }
            if (!password) {
                this.showAlert(null, this.t.instant('pages.login.passwordEmpty'));
                return;
            }
            type = 'crm';
            countryCallingCode = null;
            brandId = this.brand.id;
        }
        this.loading(true);
        this.memberApiService.login(username, password, type, countryCallingCode, brandId).subscribe(function (member) {
            var sessionId = member.sessionId;
            if (sessionId) {
                _this.memberService.putMember(member);
                // delivery new logic
                // call api to get current member's address
                _this.deliveryHandling();
                return;
                if (_this.systemConfigService.hideMemberProfilePage) {
                    _this.navigationService.popPage();
                }
                else {
                    var page = _this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                    var params = { isEditMode: !_this.isProfileCompleted(member) };
                    _this.pushByName(page, params, { replaceUrl: true }).then(function () {
                        if (!_this.isProfileCompleted(member)) {
                            _this.showAlert('', _this.t.instant('pages.login.incomplete-profile'));
                        }
                    });
                }
            }
        }, function (err) {
            _this.loading(false);
            console.error(err);
            _this.showError(err);
        }, function () {
            _this.loading(false);
        });
    };
    DeliveryLoginPage.prototype.deliveryHandling = function () {
        var _this = this;
        this.addressManagerService.getAddresses(this.orderService.store).subscribe(function (addressArr) {
            console.log('getAddresses #######', addressArr);
            // 可配送范围内的地址数组
            var addressArrBeyond = addressArr.filter(function (address) { return !address['beyond']; });
            if (addressArrBeyond.length >= 1) {
                _this.addressManagerService.findDefaultAddress().then(function (defautlAddress) {
                    _this.addressManagerService.saveCurrentAddress(defautlAddress);
                    _this.addressManagerService.calculateAddress(defautlAddress, _this.orderService.store).subscribe(function (result) {
                        _this.loading(false);
                    }, function (err) {
                        _this.loading(false);
                        return _this.showAlert(err.status, err['error']);
                    });
                });
                if (_this.fromPage === 'CategoryListPage' || _this.fromPage === 'ItemGridScrollPage') {
                    _this.backButtonClick();
                    // before forcing login , save the order message with Tourists,so need to clear the local object
                    var key = _this.store.id + '.savedOrder';
                    _this.configService.putPreference(key, null);
                    return;
                }
                _this.popToRoot().then(function () {
                    if (_this.loginCallBack) {
                        _this.loginCallBack();
                    }
                });
            }
            else {
                var page = 'AddressDialog';
                _this.pushByName(page, {
                    fromPage: 'deliveryLogin',
                    isRoute: true,
                    loginCb: function () {
                        _this.loginCallBack();
                    }
                }, {}).then(function () {
                });
            }
        });
    };
    DeliveryLoginPage.prototype.onFacebookLoginClick = function () {
        var _this = this;
        this.openTermsModal(this.brand, function (data) {
            _this.fb(data);
        }, TermsConditionsPage, true, true);
    };
    DeliveryLoginPage.prototype.fb = function (tncData) {
        var _this = this;
        this.loading(true);
        // FB.getLoginStatus((response) => {
        //     if(response.status === 'connected'){
        //         console.log(response);
        //         var data = response.authResponse
        //         // this.showAlert("",JSON.stringify(response));
        //         this.fbLoginHandling(data["userID"],data["accessToken"]);
        //     }else{
        FB.login(function (response) {
            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                var data = response.authResponse;
                _this.fbLoginHandling(data['userID'], data['accessToken'], true, tncData.marketing);
            }
            else {
                _this.loading(false);
                _this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        }, { auth_type: 'reauthenticate' });
    };
    DeliveryLoginPage.prototype.fbLoginHandling = function (fbId, token, join, marketing) {
        var _this = this;
        if (join === void 0) { join = false; }
        this.memberApiService.FBloginOrRegistration(fbId, token, true, this.store.id, this.brand.id, marketing).subscribe(function (member) {
            console.log(member);
            _this.loading(false);
            var sessionId = member.sessionId;
            // this.showAlert("",JSON.stringify(member));
            if (sessionId) {
                _this.memberService.putMember(member);
                // deal with the delivery condition with fblogin
                _this.deliveryHandling();
                return;
                var page = _this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                var params = { isEditMode: !_this.isProfileCompleted(member) };
                _this.pushAndRemovePage(page, params, null, function () {
                    if (!_this.isProfileCompleted(member)) {
                        _this.showAlert('', _this.t.instant('pages.login.incomplete-profile'));
                    }
                });
            }
        }, function (err) {
            _this.loading(false);
            console.log(err);
            _this.showError(err);
        });
    };
    DeliveryLoginPage.prototype.registration = function () {
        this.pushByName('VerifyRegistrationPage', { fromPage: DeliveryLoginPage, store: this.store });
    };
    DeliveryLoginPage.prototype.resetPassword = function () {
        var store = this.orderManager.store;
        if (!store)
            store = this.store;
        if (store && store.crm && store.crm.links && store.crm.links['forgetpw']) {
            // open in new tab
            window.open(store.crm.links['forgetpw']['url']);
        }
        else {
            this.pushByName('ResetPasswordPage', { username: this.form.email, isForgotPassword: true, brand: this.brand });
        }
    };
    DeliveryLoginPage.prototype.initGoogle = function () {
        window['onGoogleSignIn'] = this.onSignIn;
        var params = {};
        params['id'] = 'google-login-script';
        this.configService.loadScript('https://apis.google.com/js/platform.js', function () {
        }, params);
    };
    DeliveryLoginPage.prototype.onSignIn = function (googleUser) {
        var profile = googleUser.getBasicProfile();
        var id = profile.getId();
        var id_token = googleUser.getAuthResponse().id_token;
        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
        console.log('token:' + id_token);
        var evt = new CustomEvent('google-login-handling', {
            detail: {
                id: id,
                token: id_token
            }
        });
        window.dispatchEvent(evt);
    };
    DeliveryLoginPage.prototype.clickGoogleLogin = function () {
        this.googleLoginClicked = true;
    };
    DeliveryLoginPage.prototype.selectMode = function (type) {
        this.mode = type;
    };
    DeliveryLoginPage.prototype.googleLoginHandling = function (id, token) {
        var _this = this;
        if (this.googleLoginClicked) {
            this.loading(true);
            this.memberApiService.googleLogin(id, token).subscribe(function (member) {
                console.log(member);
                var sessionId = member.sessionId;
                if (sessionId) {
                    _this.memberService.putMember(member);
                    // deal with the delivery condition with googlelogin
                    _this.deliveryHandling();
                    return;
                    // console.log(this.navController.getViews());
                    _this.pushAndRemovePage('MemberRewardPage', {}, null, function () {
                    });
                    // this.push(MemberRewardPage);
                }
            }, function (err) {
                _this.loading(false);
                console.error(err);
                _this.showError(err);
            }, function () {
                _this.loading(false);
            });
        }
    };
    return DeliveryLoginPage;
}(MobileBasePage));
export { DeliveryLoginPage };
