import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnlockerComponent} from './unlocker';
import {IonicModule} from '@ionic/angular';

@NgModule({
    declarations: [UnlockerComponent],
    imports: [
        CommonModule,
        IonicModule.forRoot(),
    ]
})
export class UnlockerModule {
}
