import { Pipe, PipeTransform } from '@angular/core';

// 自定义管道 timeStringLabel
@Pipe({
  name: 'timeStringLabel'
})

// 创建的管道的类
export class TimeStringLabelPipe implements PipeTransform {
    transform(pickUpTime?: number, withSecond: boolean = false): string {
        let date: Date;
        if (pickUpTime) {
            date = new Date(pickUpTime);
        } else {
            date = new Date();
        }
        let timeString = date.getHours() + ':';
        if (date.getMinutes() === 0) {
            timeString = timeString + '00';
        } else if (date.getMinutes() <= 9) {
            timeString = timeString + '0' + date.getMinutes();
        } else {
            timeString = timeString + date.getMinutes();
        }
        if (withSecond) {
            timeString = timeString + ':';
            if (date.getSeconds() === 0) {
                timeString = timeString + '00';
            } else if (date.getSeconds() <= 9) {
                timeString = timeString + '0' + date.getSeconds();
            } else {
                timeString = timeString + date.getSeconds();
            }
        }
        return timeString;
      }
}
