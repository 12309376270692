/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-back-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "../../directives/throttle-click/throttle-click";
import * as i7 from "./custom-back-button.component";
import * as i8 from "../../../core/services/order.service";
var styles_CustomBackButtonComponent = [i0.styles];
var RenderType_CustomBackButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomBackButtonComponent, data: {} });
export { RenderType_CustomBackButtonComponent as RenderType_CustomBackButtonComponent };
function View_CustomBackButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.overwriteTranslateKey)); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomBackButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["color", ""], ["ion-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.overwriteTranslateKey)); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomBackButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomBackButtonComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomBackButtonComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.darkText; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.darkText; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CustomBackButtonComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("global.previous")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomBackButtonComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["color", ""], ["ion-text", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("global.previous")); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomBackButtonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomBackButtonComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomBackButtonComponent_7)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.darkText; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.darkText; _ck(_v, 4, 0, currVal_1); }, null); }
function View_CustomBackButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomBackButtonComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomBackButtonComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overwriteTranslateKey; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.overwriteTranslateKey; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CustomBackButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-button", [["appThrottleClick", ""], ["class", "back-btn AQA-back-btn"]], null, [[null, "throttleClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).clickEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("throttleClick" === en)) {
        var pd_1 = (_co.onBackButtonClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_IonButton_0, i4.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i5.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(2, 212992, null, 0, i6.ThrottleClickDirective, [], null, { throttleClick: "throttleClick" }), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "ion-icon", [["name", "arrow-back"]], null, null, null, i4.View_IonIcon_0, i4.RenderType_IonIcon)), i1.ɵdid(4, 49152, null, 0, i5.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CustomBackButtonComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = "arrow-back"; _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.forceHideText && !_co.hideBackText); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_CustomBackButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "custom-back-button", [], null, null, null, View_CustomBackButtonComponent_0, RenderType_CustomBackButtonComponent)), i1.ɵdid(1, 49152, null, 0, i7.CustomBackButtonComponent, [i8.OrderService], null, null)], null, null); }
var CustomBackButtonComponentNgFactory = i1.ɵccf("custom-back-button", i7.CustomBackButtonComponent, View_CustomBackButtonComponent_Host_0, { forceHideText: "forceHideText", darkText: "darkText", overwriteTranslateKey: "overwriteTranslateKey" }, { backButtonClick: "backButtonClick" }, []);
export { CustomBackButtonComponentNgFactory as CustomBackButtonComponentNgFactory };
