import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {BaseService} from '../base/base-service';
import {Config} from '../../config';
import {HttpClient} from '@angular/common/http';

declare var ga;

@Injectable({providedIn: 'root'})
export class GoogleAnalyticsService extends BaseService {

    config: Config;
    sender: string;
    setting: any;
    targetId: string;
    trackerName: string[];

    constructor(private http: HttpClient, public configService: ConfigService) {

        super();
        this.config = new Config();
        this.setting = this.config.gaConfig();
        if (this.setting) {
            this.sender = this.setting['name'] + '.send';

        }
        // this.createTracker(this.setting);
    }

    createTracker(setting: any) {
        const trackerId = this.getMainTrackerId();
        console.log('init ga', trackerId);

        ga('create', trackerId, window.location.hostname);
        if (this.setting) {
            ga('create', setting['trackerId'], window.location.hostname, setting['name']);
            if (!this.trackerName) {
                this.trackerName = [setting['name']];
            } else if (this.trackerName.indexOf(setting['name']) == -1) {
                this.trackerName.push(setting['name']);
            } else {
                console.log('creating duplicated ga tracker', setting['name']);
            }
        }
    }

    createTrackerById(trackId, trackname) {
        console.log('init ga', trackId + ':' + trackname);
        ga('create', trackId, window.location.hostname, trackname);
        if (!this.trackerName) {
            this.trackerName = [trackname];
        } else if (this.trackerName.indexOf(trackname) == -1) {
            this.trackerName.push(trackname);
        } else {
            console.log('creating duplicated ga tracker', trackname);
        }
    }

    pageViewTrack(pagePath: string, oninit?) {
        if (!oninit) {
            ga('send', 'pageview', pagePath);
        }
        // if (this.setting) {
        //     ga(this.sender, 'pageview', pagePath);
        // }
        if (this.trackerName) {
            this.trackerName.forEach(tracker => {
                ga(tracker + '.send', 'pageview', pagePath);
            });
        }
    }

    eventTrack(page: string, event: string, label: string) {
        ga('send', 'event', page, event, label);
        // if (this.setting) {
        //     ga(this.sender, 'event', page, event, label);
        // }
        if (this.trackerName) {
            this.trackerName.forEach(tracker => {
                ga(tracker + '.send', 'event', page, event, label);
            });
        }
    }

    getMainTrackerId(): string {
        return this.configService.isPrd() ? this.config.ga_prd.trackerId : this.config.ga_uat.trackerId;
    }


}
