import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {ModifierGuard} from 'src/app/core/guards/modifier.guard';
import {MultiStepComboPage} from './multi-step-combo-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [MultiStepComboPage],
    entryComponents: [MultiStepComboPage],
    imports: [
        SharedModule,
    ]
})
export class MultiStepComboModule {
}
