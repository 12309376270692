<!--
  Generated template for the DineIn page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content overflow-scroll="false" class="no-bg">

    <div class="survey-box">
        <div class="survey-wrapper" [ngStyle]="{'height.px': deHeight - 128}">
            <iframe id="target-dom-node" style="width:100%;height: 100%;"></iframe>
        </div>
        <ion-button class="close-btn" (click)="$event.stopPropagation();dismissClicked()">
            <ion-icon name="ios-close" slot="icon-only" color="dark"></ion-icon>
        </ion-button>
    </div>

    
</ion-content>
