<!--
  Generated template for the Passcode page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content padding (click)="dismissClicked();$event.stopPropagation()">

    <div class="custom-dialog" (click)="$event.stopPropagation()">

        <ion-icon name="ios-close" class="close-icon" (click)="dismissClicked()"></ion-icon>

        <div class="text-box">
            <p>{{"pages.splash.enterPasscode" | translate}}</p>
        </div>
        <div class="input-box">
            <ion-input #input type="tel" name="passcode" maxlength="8" [(ngModel)]="passcode" autofocus></ion-input>
            <div ion-text color="danger" class="error-text-box">
                <p *ngIf="wrong" class="animated fadeInUps">{{"pages.splash.errorPasscode" | translate}}</p>
            </div>
        </div>
        <div class="btn-box">
            <ion-button fill="clear" (click)="submit()"
                        class="confirm-btn">{{"buttons.confirm" | translate}}</ion-button>
        </div>
    </div>

</ion-content>
