/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./enlargePage.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./enlargePage";
var styles_EnlargePage = [i0.styles];
var RenderType_EnlargePage = i1.ɵcrt({ encapsulation: 0, styles: styles_EnlargePage, data: {} });
export { RenderType_EnlargePage as RenderType_EnlargePage };
export function View_EnlargePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", " is-display"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "div-btn-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ion-icon", [["name", "close-circle-outline"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(4, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_1 = "close-circle-outline"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.imgurl, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_EnlargePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-enlargePage", [], null, null, null, View_EnlargePage_0, RenderType_EnlargePage)), i1.ɵdid(1, 114688, null, 0, i4.EnlargePage, [i3.NavParams, i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnlargePageNgFactory = i1.ɵccf("app-enlargePage", i4.EnlargePage, View_EnlargePage_Host_0, { imgParams: "imgParams" }, {}, []);
export { EnlargePageNgFactory as EnlargePageNgFactory };
