<!--
  Generated template for the DineIn page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content padding class="no-bg">

    <!-- <div class="ad-img-box" (click)="$event.stopPropagation();adClicked()">

        <img class="ad" src="{{getImage()}}"/>
        <ion-button class="close-btn" (click)="$event.stopPropagation();dismissClicked()">
            <ion-icon name="close" color="dark"></ion-icon>
        </ion-button>
    </div> -->



    <div class="slider-container" (click)="$event.stopPropagation();">
        <ng-container *ngIf="ads.length > 1; else elseTemplate">
            <ion-button class="close-btn" (click)="$event.stopPropagation();dismissClicked()">
                <ion-icon name="close" ></ion-icon>
            </ion-button>
            <ion-slides pager="true" [options]="slideOpts">
                

                <ion-slide *ngFor="let ad of ads" (click)="adClicked(ad)">
                    <ng-container *ngIf="!ad?.desc; else elseTemplate">
                        <div class="div-slide-img">
                           
                            <img src="{{ad?.images?.default?.url}}" alt="">
                        </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="div-announ" [innerHTML]="ad?.desc | safeHtml">
                        </div>
                    </ng-template>
                </ion-slide>
            </ion-slides>
        </ng-container>
        <ng-template #elseTemplate>
            <ion-button class="close-btn" (click)="$event.stopPropagation();dismissClicked()">
                <ion-icon name="close" ></ion-icon>
            </ion-button>
            <div class="div-slide-img" (click)="adClicked(ads[0])">
                <img src="{{ads[0]?.images?.default?.url}}" alt="">
            </div>
        </ng-template>
    
    
    </div>

</ion-content>
