import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-pre-order',
    templateUrl: 'pre-order.html',
    styleUrls: ['pre-order.scss']
})
export class PreOrderDialog implements OnInit {


    constructor(public modalController: ModalController) {

    }

    ngOnInit() {
        console.log('ngOnInit Popup');
    }


    dismissClicked() {

        this.modalController.dismiss(null);

        // this.navController.pop({animate: false});

        console.log('dismiss?');

    }

}
