import {BaseService} from '../base/base-service';
import {Member, Offer} from 'aigens-ng-core';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {UUID} from 'angular2-uuid';


@Injectable({providedIn: 'root'})
export class MemberService extends BaseService {

    country: string;
    offers: Offer[];
    hasMembership = false;
    mode: 'member' | 'anno' = 'anno';
    uuid: string;
    name: string;
    memberSubscribe: Subject<Member>;
    member: Member;
    crmId: string

    constructor(public configs: ConfigService) {
        super();
        this.loadAigensMember();
        this.memberSubscribe = new Subject();
    }

    loadAigensMember() {


        this.configs.getPreference('aigens.member').then((member) => {

            console.log('member loaded', member);

            if (member) {
                this.member = member;
                this.configs.setSessionId(this.member.sessionId);
            }

            this.memberSubscribe.next(member);

            console.log('sub fired');

        });

        this.configs.getPreference('crmId').then((crmId) => {

            console.log('court crmId loaded', crmId);

            if (crmId) {
                this.crmId = crmId;
            }

        });
    }

    putMember(member: Member) {
        this.member = member;
        console.log('this.member', this.member, this.memberSubscribe);
        this.memberSubscribe.next(member);
        this.configs.setSessionId(this.member.sessionId);
        this.configs.storage.set('aigens.member', member);

    }

    isAdmin() {

        const member = this.getMember();
        if (member) {
            return member.admin === true;
        }

        return false;

    }

    clearSession() {

        this.member = null;
        this.configs.clearSessionId();
        this.configs.storage.set('aigens.member', null);
        this.configs.setLocal('session.expire', null);
    }

    clear() {
        this.clearSession();
        this.offers = [];
        this.hasMembership = false;
    }


    // getSessionId(): string {
    //
    //     const member = this.getMember();
    //     if (member) {
    //         return member.sessionId;
    //     }
    //     return this.configs.getCookie('sid');
    // }


    getMember(): Member {

        return this.member;
    }


    initMember(mode = 'anno') {
        // if (mode === 'anno') {
        //     this.mode = 'anno';
        //     // make a fake member;
        //     if (this.configs.getLocal('anno.uuid') && this.configs.getLocal('anno.name')) {
        //         this.uuid = this.configs.getLocal('anno.uuid');
        //         this.name = this.configs.getLocal('anno.name');
        //     } else {
        //         this.uuid = UUID.UUID();
        //         // this.name = new chance().name();
        //         this.configs.setLocal('anno.uuid', this.uuid);
        //         this.configs.setLocal('anno.name', this.name);
        //
        //     }
        //
        // }
    }


}
