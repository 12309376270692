import {Component, Injector, OnInit} from '@angular/core';
import {Data, Reward} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';
import {NavParams} from '@ionic/angular';
import {isAvailableReward} from '../../shared/utilities/checkFormatTool';


@Component({
    selector: 'page-reward-list',
    templateUrl: 'reward-list.html',
    styleUrls: ['reward-list.scss']
})
export class RewardListDialog extends MobileBasePage implements OnInit {

    rewards: Reward[] = [];
    isBounce = false;

    constructor(public injector: Injector, public navParams: NavParams) {

        super(injector);

        this.rewards = this.navParams.get('rewards');
    }


    ngOnInit() {
        console.log('ngOnInit VariationPage');
    }


    dismissClicked() {
        this.modalController.dismiss(null);

    }

    getImage(reward: Reward) {
        return Data.getImage(reward, 'default', 100, false);
    }

    bounce() {
        this.isBounce = true;
        setTimeout(() => {
            this.isBounce = false;
        }, 1000);
    }

    isAvailableReward(reward: Reward) {
        return isAvailableReward(reward);
    }

    onRewardClick(reward: Reward) {
        if (isAvailableReward(reward)) {
            this.modalController.dismiss(reward);
            this.bounce();
        }
    }

    canRedeem(reward: Reward): boolean {
        return reward.cost < this.memberService.member.membership.stamps;

    }


}
