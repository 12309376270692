import {Ad, Store} from 'aigens-ng-core';
import {Component} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {RouteTable} from 'src/app/core/route-table';
import {NavigationService} from '../../core/services/navigation.service';
import {MemberService} from '../../core/services/member.service';

/**
 * Generated class for the DineIn page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'page-popup',
    templateUrl: 'popup.html',
    styleUrls: ['popup.scss']
})
export class PopupDialog {
    store: Store;
    ads: Ad[];
    slideOpts = {
        initialSlide: 0,
        speed: 400,
        autoplay: {
            delay: 4000
        },
        loop: true,
    };
    adsArr: any = [];

    constructor(public memberService: MemberService, public navigationService: NavigationService, public modalController: ModalController, public navParams: NavParams) {

        this.store = this.navigationService.getNavParams('store');
        this.ads = this.navigationService.getNavParams('ads');
        // this.setAds(this.ads);
        console.log('nav', navParams, this.store);
        console.log('popup', this.ads);
    }


    dismissClicked() {

        this.modalController.dismiss(null);

        // this.navController.pop({animate: false});

        console.log('dismiss?');

    }

    adClicked(ad = null) {
        console.log('ad clicked', ad);
        this.modalController.dismiss(ad['links']);
        // this.pushByUrl()
        if (ad['links']) return;
        if (this.memberService.getMember()) {
            this.navigationService.pushByUrl(RouteTable['MemberRewardPage']);
        } else {
            this.navigationService.pushByUrl(RouteTable['LoginPage'], {
                store: this.store,
                fromPage: 'ByodSplashPage', logincb: () => {
                    // this.next();
                }
            });
        }
    }


}
