<!--
  Generated template for the SelectTable page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
-->



<ion-content padding class="content" (click)="dismissClicked()">

    <ion-list class="form" (click)="formClicked();$event.stopPropagation();" [formGroup]="formGroup">

        <ion-item no-lines>
            <ion-label class="title" color="primary">{{"pages.select-table.select-location" | translate}}</ion-label>
        </ion-item>

        <ion-item *ngIf="rooms" class="input-item">
            <ion-label stacked>{{"pages.select-table.room" | translate}}</ion-label>
            <!--
            <ion-input type="text" [(ngModel)]="data.room" name="title" placeholder="Room Number"></ion-input>
            -->
            <ion-select formControlName="room" [(ngModel)]="data.room" placeholder="{{'pages.select-table.please-select' | translate}}">
                <ion-select-option *ngFor="let room of rooms" value="{{room}}">{{room}}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item class="input-item">
            <ion-label stacked>{{"pages.select-table.table" | translate}}</ion-label>
            <ion-input formControlName="table" type="text" [(ngModel)]="data.table" name="table" placeholder="{{'pages.select-table.table-number' | translate}}"></ion-input>
        </ion-item>

        <ion-item no-lines>
            <ion-button color="primary" size="large" expand="full"
                        (click)="confirmClicked()">{{'buttons.ok' | translate}}</ion-button>
        </ion-item>

    </ion-list>

</ion-content>
