/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../shared/pipes/safe-html.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./popup";
import * as i8 from "../../core/services/member.service";
import * as i9 from "../../core/services/navigation.service";
var styles_PopupDialog = [i0.styles];
var RenderType_PopupDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupDialog, data: {} });
export { RenderType_PopupDialog as RenderType_PopupDialog };
function View_PopupDialog_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "div-slide-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.images == null) ? null : ((_v.parent.context.$implicit.images.default == null) ? null : _v.parent.context.$implicit.images.default.url))), ""); _ck(_v, 2, 0, currVal_0); }); }
function View_PopupDialog_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "div-announ"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 0), ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.desc))); _ck(_v, 0, 0, currVal_0); }); }
function View_PopupDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-slide", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.adClicked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(1, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PopupDialog_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseTemplate", 2]], 0, 0, null, View_PopupDialog_4))], function (_ck, _v) { var currVal_0 = !((_v.context.$implicit == null) ? null : _v.context.$implicit.desc); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_PopupDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ion-button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(2, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(4, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "ion-slides", [["pager", "true"]], null, null, null, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(6, 49152, null, 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { options: [0, "options"], pager: [1, "pager"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PopupDialog_2)), i1.ɵdid(8, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "close"; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.slideOpts; var currVal_2 = "true"; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.ads; _ck(_v, 8, 0, currVal_3); }, null); }
function View_PopupDialog_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ion-button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "div-slide-img"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.adClicked(_co.ads[0]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "close"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", ((_co.ads[0] == null) ? null : ((_co.ads[0].images == null) ? null : ((_co.ads[0].images.default == null) ? null : _co.ads[0].images.default.url))), ""); _ck(_v, 5, 0, currVal_1); }); }
export function View_PopupDialog_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.SafeHtmlPipe, [i6.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "ion-content", [["class", "no-bg"], ["padding", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(3, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "div", [["class", "slider-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupDialog_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["elseTemplate", 2]], null, 0, null, View_PopupDialog_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.ads.length > 1); var currVal_1 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_PopupDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-popup", [], null, null, null, View_PopupDialog_0, RenderType_PopupDialog)), i1.ɵdid(1, 49152, null, 0, i7.PopupDialog, [i8.MemberService, i9.NavigationService, i3.ModalController, i3.NavParams], null, null)], null, null); }
var PopupDialogNgFactory = i1.ɵccf("page-popup", i7.PopupDialog, View_PopupDialog_Host_0, {}, {}, []);
export { PopupDialogNgFactory as PopupDialogNgFactory };
