var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SdkBaseService } from './sdk-base-service';
import * as i0 from "@angular/core";
var SdkConfigService = /** @class */ (function (_super) {
    __extends(SdkConfigService, _super);
    function SdkConfigService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.message = 'init';
        return _this;
    }
    SdkConfigService.prototype.getServiceName = function () {
        return 'ConfigService';
    };
    SdkConfigService.prototype.getConfig = function (callback) {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('getConfig', {}, callback);
    };
    SdkConfigService.prototype.back = function () {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('back', {});
    };
    SdkConfigService.prototype.getParams = function (callback) {
        if (this.testing) {
            var result = {
                member: {
                    name: 'testing',
                    email: 'ernest.lee@aigens.com',
                    // session: 'e026f56f0767cd4b4cbd30374ddad208', // 200116
                    language: 'zh',
                    phone: '98197556',
                    memberId: '200116',
                    cardNo: "8140003810",
                    source: "crystalJade"
                }
            };
            callback(result);
            return;
        }
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('getParams', {}, callback);
    };
    SdkConfigService.prototype.getPreference = function (pref, name, callback) {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('getPreference', {
            pref: pref,
            name: name
        }, callback);
    };
    SdkConfigService.prototype.putPreference = function (pref, name, value) {
        if (!this.isAvailable()) {
            return;
        }
        this.callNative('putPreference', {
            pref: pref,
            name: name,
            value: value
        });
    };
    SdkConfigService.ngInjectableDef = i0.defineInjectable({ factory: function SdkConfigService_Factory() { return new SdkConfigService(); }, token: SdkConfigService, providedIn: "root" });
    return SdkConfigService;
}(SdkBaseService));
export { SdkConfigService };
