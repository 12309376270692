var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Data, Store } from 'aigens-ng-core';
import 'rxjs/Rx';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
var StoreService = /** @class */ (function (_super) {
    __extends(StoreService, _super);
    function StoreService(http, configs) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    // use in store list
    StoreService.prototype.searchStores = function (groupId, q, latitude, longitude, regionCodes, radius, limit) {
        if (latitude === void 0) { latitude = null; }
        if (longitude === void 0) { longitude = null; }
        if (regionCodes === void 0) { regionCodes = null; }
        if (radius === void 0) { radius = null; }
        if (limit === void 0) { limit = null; }
        var url = '/api/v1/store/store.json?groupId=' + groupId;
        var params = {};
        params['q'] = q;
        if (latitude != null && longitude != null) {
            params['latitude'] = latitude;
            params['longitude'] = longitude;
        }
        if (radius != null) {
            params['radius'] = radius;
        }
        if (limit != null) {
            params['limit'] = limit;
        }
        if (regionCodes != null && regionCodes.length > 0) {
            params['region'] = regionCodes.join(',');
        }
        var aq = this.aq;
        aq.url = url;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Store, jo['data']); }));
    };
    StoreService.prototype.verifyTableCode = function (code, storeId) {
        var url = '/api/v1/menu/spot.json?storeId=' + storeId + '&code=' + code;
        this.aq.url = url;
        this.aq.method = 'get';
        return this.aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    StoreService.ngInjectableDef = i0.defineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService)); }, token: StoreService, providedIn: "root" });
    return StoreService;
}(BaseService));
export { StoreService };
